// import ServiceService from '../../services/service.service';

// import Vue from 'vue'

import {
    SET_SHOW_LOADER,
    MAIN_SET_SNACKBAR
} from '../mutation-types'


export default {
    namespaced: true,

    state: {
        showLoader: false,
        snackbar: {
            show: false,
            text: ""
        },
    },

    getters: {

        getShowLoader: (state) => {
            return state.showLoader;
        },
        getSnackbar(state) {
            return state.snackbar;
        },
    },

    actions: {

        // Creates new service
        async setShowLoader({ commit }, flag) {
            commit('SET_SHOW_LOADER', flag);
        },

        // Sets if the settings screen is being showed
        async setSnackbar({ commit }, snackbar) {
            commit('MAIN_SET_SNACKBAR', snackbar);
        },
    },

    mutations: {
        [SET_SHOW_LOADER](state, flag) {
            state.showLoader = flag;
        },
        [MAIN_SET_SNACKBAR](state, snackbar) {
            state.snackbar = snackbar;
        },
    }
}