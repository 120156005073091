<template>
    <v-alert
      text
      outlined
      color="deep-orange"
      icon="mdi-information"
    >
      <ul
        v-for="message in messages"
        :key="message"
      >
          <li>{{message}}</li>
      </ul>
    </v-alert>
</template>

<script>
// Store
import { mapActions,  } from "vuex";
export default {
  name: "StatusMessage",
  props: ["messages"],  
  methods:{
    ...mapActions({
      messageShowing: "status_message/messageShowing",
    }),
    async onClose(){
      console.log("onClose")
      await this.messageShowing(false)
    }
  }
};
</script>
<style scoped>
</style>