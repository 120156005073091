export const MAIN_SET_SNACKBAR = 'MAIN_SET_SNACKBAR'

export const SET_USER = 'SET_USER'
export const SET_USER_INFO = 'SET_USER_INFO'
export const DELETE_USER = 'DELETE_USER'
export const LOAD_JOBS = 'LOAD_JOBS'

export const MESSAGES_ADD = 'MESSAGES_ADD'
export const MESSAGES_SHOWING = 'MESSAGES_SHOWING'
export const MESSAGES_EMPTY = 'MESSAGES_EMPTY'

export const SET_SHOW_LOADER = 'SET_SHOW_LOADER'