import axios from 'axios';
import authHeader from './auth-header';
import authFileHeader from './auth-file-header';

class AuthService {

    async token() {
        return new Promise((resolve, reject) => {
            axios.get(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/session/token`
                    new URL(`/session/token`, process.env.VUE_APP_SERVER_ENDPOINT),
                )
                .then(response => {

                    console.log("-- token --")
                    console.log(response)

                    resolve(response.data);
                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async getUserInfo() {

        console.log("getUserInfo");

        return new Promise((resolve, reject) => {

            // axios.get(process.env.VUE_APP_SERVER_ENDPOINT + `/user/${uid}?_format=json`, {
            axios.get(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/api/user/info?_format=json`, 
                    new URL(`/api/user/info?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader()
                    })
                .then(response => {

                    console.log("-- response sxx --")
                    console.log(response)

                    if (response.status == 200) {
                        localStorage.setItem('info', JSON.stringify(response.data[0]));
                        resolve(response.data[0]);
                    }

                    reject(false);

                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async updateUserInfo(uid, payload) {
        console.log("updateUserInfo");
        return new Promise((resolve, reject) => {
            axios.patch(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/user/${uid}?_format=json`,
                    new URL(`/user/${uid}?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authHeader()
                    }
                )
                .then(response => {

                    // console.log("-- response --")
                    // console.log(response)

                    if (response.status == 200) {
                        // localStorage.setItem('info', JSON.stringify(response.data));
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {

                    console.log("-- Token Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async register(payload) {

        return new Promise((resolve, reject) => {

            const headers = `X-CSRF-Token: ${payload.token}`

            axios.post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/user/register?_format=json`, 
                    new URL(`/user/register?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        name: payload.name,
                        mail: payload.mail,
                        pass: payload.pass,
                    }, {
                        headers: headers
                    })
                .then(response => {

                    console.log("AuthService::register");
                    console.log(response);

                    if (response.status == 200) {
                        resolve(response.data);
                    } else {
                        console.log("Error");
                        resolve(false);
                    }


                }).catch(error => {

                    console.log(error);

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async login(payload) {

        console.log("== login ==");

        return new Promise((resolve, reject) => {

            const data = {
                name: payload.name,
                pass: payload.pass
            };

            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            };

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/user/login?_format=json`,
                    new URL(`/user/login?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    data, {
                        headers: headers,
                    }
                )
                .then(function(response) {

                    console.log("== login::response ==");
                    console.log(response)

                    if (response.status == 200) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                        resolve(response.data);
                    }

                    reject({ status: response.status });

                }).catch(error => {

                    console.log("== login::error ==");
                    console.log(error);

                    // https://github.com/axios/axios/issues/960
                    reject({ status: error.response.status });
                });

        })
    }

    async logout() {

        return new Promise((resolve) => {

            localStorage.removeItem('user');
            localStorage.removeItem('info');

            resolve(true);
        })
    }

    async loadImageFile(element) {

        console.log("== loadImageFile ==");

        return new Promise((resolve) => {

            var reader = new FileReader();

            reader.readAsArrayBuffer(element);

            reader.onload = () => {

                console.log("== loadImageFile onload ==");

                resolve(reader.result);

            }
        })
    }

    async uploadUserImage(payload) {
        return new Promise((resolve, reject) => {

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/file/upload/user/user/user_picture?_format=json`, 
                    new URL(`/file/upload/user/user/user_picture?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authFileHeader('user.jpg'),
                    }
                )
                .then(function(response) {

                    console.log(response);

                    if (response.status == 201) {
                        resolve(response.data.fid[0].value);
                    }

                    reject(false);

                }).catch(error => {
                    console.log(error)
                        // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async uploadImage(payload) {

        // /file/upload/{entity_type_id}/{bundle}/{field_name}:

        return new Promise((resolve, reject) => {

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/file/upload/node/job/field_images?_format=json`, 
                    new URL(`/file/upload/node/job/field_images?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authFileHeader('job.jpg'),
                    }
                )
                .then(function(response) {

                    // console.log(response);

                    if (response.status == 201) {
                        resolve(response.data.fid[0].value);
                    }

                    reject(false);

                }).catch(error => {
                    console.log(error)
                        // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async postJob(payload) {

        return new Promise((resolve, reject) => {

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/node?_format=json`, 
                    new URL(`/node?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authHeader(),
                    }
                )
                .then(function(response) {

                    if (response.status == 201) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {
                    console.log(error)
                        // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async getCustomerJobDetail(id) {
        console.log("== getCustomerJobDetail == ");
        return new Promise((resolve, reject) => {
            axios.get(
                    new URL(`/api/customer/job-detail/${id}?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader()
                    })
                .then(response => {

                    // console.log("-- jobs --")
                    // console.log(response)
                    // console.log(response.data[0].estimates)

                    // The view returns an array with one value. 
                    // If empty is an error
                    if (response.data.length) {
                        resolve(response.data[0]);
                    } else {
                        reject(false);
                    }

                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async getCustomerJobEstimateDetail(eid) {
        console.log("== getCustomerJobEstimateDetail == ");
        return new Promise((resolve, reject) => {
            axios.get(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/api/customer/job-estimate-detail/${eid}?_format=json`, 
                    new URL(`/api/customer/job-estimate-detail/${eid}?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader()
                    })
                .then(response => {

                    // console.log("-- Estimate Detail --")
                    // console.log(response)

                    // The view returns an array with one value. 
                    // If empty is an error
                    if (response.data.length) {
                        resolve(response.data[0]);
                    } else {
                        reject(false);
                    }

                }).catch(error => {

                    console.log("-- Estimate Detail Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async apiCustomerGetReviews(contractorId) {
        console.log("-- apiCustomerGetReviews --")

        return new Promise((resolve, reject) => {
            axios.get(
                    new URL(`/api-customer/reviews/${contractorId}?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    })
                .then(response => {

                    console.log("-- apiCustomerGetReviews response --")
                    console.log(response)

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {

                    console.log("-- apiCustomerGetReviews Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }


    async apiCustomerGetJobs(status) {
        console.log("-- apiCustomerGetJobs --")

        return new Promise((resolve, reject) => {
            axios.get(
                    new URL(`/api-customer/jobs?status=${status}&_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    })
                .then(response => {

                    console.log("-- apiCustomerGetJobs response --")
                    console.log(response)

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {

                    console.log("-- apiCustomerGetJobs Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async getJobs(status) {
        console.log("== getJobs == ");
        return new Promise((resolve, reject) => {
            // axios.get(process.env.VUE_APP_SERVER_ENDPOINT + `/node/54?_format=json`, {
            axios.get(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/api/customer/jobs?_format=json`, 
                    new URL(`/api/customer/jobs?status=${status}&_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    })
                .then(response => {

                    console.log("-- jobs --")
                    console.log(response)

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async setLocationStorage(location) {
        localStorage.setItem('location', JSON.stringify(location));
    }


    async getLocationStorage() {
        return JSON.parse(localStorage.getItem('location'));
    }

    async getContractorJobs(coordinates, tid) {
        console.log("== getContractorJobs == ");
        return new Promise((resolve, reject) => {

            var params = null;

            if (coordinates) {
                params = `/api/contractor/jobs/${coordinates}?_format=json${tid}`
            } else {
                params = `/api/contractor/jobs?_format=json`
            }

            console.log(params);

            axios.get(
                    // process.env.VUE_APP_SERVER_ENDPOINT + params, 
                    new URL(params, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    }
                )
                .then(response => {

                    console.log("-- jobs --")
                    console.log(response)

                    resolve(response.data);
                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    // ----------------------------------------
    // General
    // ----------------------------------------    

    async getCategories() {
        console.log("== getCategories == ");
        return new Promise((resolve, reject) => {
            axios.get(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/api/general/categories?_format=json`, 
                    new URL(`/api/general/categories?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    }
                )
                .then(response => {

                    // console.log(response);

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    // ----------------------------------------
    // Estimates
    // ----------------------------------------

    async postEstimate(payload) {

        return new Promise((resolve, reject) => {

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/apis/estimate?_format=json`, 
                    new URL(`/apis/estimate?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authHeader(),
                    }
                )
                .then(function(response) {

                    console.log("postEstimate");

                    console.log(response);

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {
                    console.log(error)
                        // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async postRateEstimate(payload) {

        return new Promise((resolve, reject) => {

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/apis/estimate?_format=json`, 
                    new URL(`/apis/rate-estimate?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authHeader(),
                    }
                )
                .then(function(response) {

                    console.log("postRateEstimate");

                    console.log(response);

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {
                    console.log(error)
                        // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async postAcceptEstimate(payload) {

        return new Promise((resolve, reject) => {

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/apis/estimate?_format=json`, 
                    new URL(`/apis/accept-estimate?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authHeader(),
                    }
                )
                .then(function(response) {

                    console.log("postAcceptEstimate");

                    console.log(response);

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {
                    console.log(error)
                        // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async postRelistEstimate(payload) {

        return new Promise((resolve, reject) => {

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/apis/estimate?_format=json`, 
                    new URL(`/apis/relist-estimate?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authHeader(),
                    }
                )
                .then(function(response) {

                    console.log("postRelistEstimate");

                    console.log(response);

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {
                    console.log(error)
                        // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async postCompleteEstimate(payload) {

        return new Promise((resolve, reject) => {

            return axios
                .post(
                    // process.env.VUE_APP_SERVER_ENDPOINT + `/apis/estimate?_format=json`, 
                    new URL(`/apis/complete-estimate?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT),
                    payload, {
                        headers: authHeader(),
                    }
                )
                .then(function(response) {

                    console.log("postCompleteEstimate");

                    console.log(response);

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {
                    console.log(error)
                        // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async getEstimate(estimateId) {

        return new Promise((resolve, reject) => {
            axios.get(
                    new URL(`/apis/estimate/${estimateId}?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    })
                .then(response => {

                    console.log("-- getEstimate from service --");
                    console.log(response);

                    // The view returns an array with one value. 
                    // If empty is an error
                    if (response.data.message) {
                        resolve(response.data.message);
                    } else {
                        // reject(false);
                        resolve(false);
                    }
                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async getJobDetailEstimatesParent(jobId) {

        return new Promise((resolve, reject) => {
            axios.get(
                    new URL(`/api/customer/job-detail-estimates-parent/${jobId}?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    })
                .then(response => {

                    console.log("-- getJobDetailEstimatesParent from service --")
                    console.log(response)

                    // The view returns an array with one value. 
                    // If empty is an error
                    if (response.data.length) {
                        resolve(response.data[0]);
                    } else {
                        // reject(false);
                        resolve(false);
                    }
                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    async getEstimates(jobId) {

        return new Promise((resolve, reject) => {
            axios.get(
                    new URL(`/api/customer/job-estimates/${jobId}?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    })
                .then(response => {

                    console.log("-- getEstimates from service --")
                    console.log(response)

                    // The view returns an array with one value. 
                    // If empty is an error
                    if (response.data.length) {
                        resolve(response.data[0]);
                    } else {
                        // reject(false);
                        resolve(false);
                    }
                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

    // Returns the contractor estimates
    async getContractorEstimates() {
        console.log("== getContractorEstimates == ");
        return new Promise((resolve, reject) => {
            axios.get(
                    new URL(`/api/contractor/estimates?_format=json`, process.env.VUE_APP_SERVER_ENDPOINT), {
                        headers: authHeader(),
                    })
                .then(response => {

                    console.log("-- getContractorEstimates #2 --")
                    console.log(response)

                    if (response.status == 200) {
                        resolve(response.data);
                    }

                    reject(false);

                }).catch(error => {

                    console.log("-- toekn Error --")
                    console.log(error)

                    // https://github.com/axios/axios/issues/960
                    reject(error);
                });
        })
    }

}

export default new AuthService();