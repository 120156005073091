<template>
  <Page>
    <template v-slot:title> FAQ </template>
    <template v-slot:content>
      <div>
        <p><strong>What is SuperService?</strong></p>

        <p>
          SuperService is designed to save the customer and contractor time and
          money by eliminating the need to call and schedule multiple
          appointments to receive and submit estimates.
        </p>

        <p><strong>How do customers use SuperService?</strong></p>

        <p>
          Create a profile and then create a new service request. &nbsp;Choose
          the category that is the best match and fill in the description area
          the best you can with what you need done. &nbsp;Be sure to include
          measurements, what you know or think the problem might be,
          year-make-model if applicable, and upload as many photos so that
          contractors can have a better understanding. &nbsp;Select the dates
          you would like the work performed and then just wait for the estimates
          to arrive. &nbsp;Choose the contractor that best suites your needs and
          once selected the contractor will receive your contact information.
        </p>

        <p><strong>How do contractors use SuperService?</strong></p>

        <p>
          Create a profile and search for service requests that you have
          training, previous experience, or feel confident in completing.
          &nbsp;If you find work in the area of your preference and without
          current estimates or with estimates you feel you could compete with
          then pay the $5 monthly fee and begin placing estimates.
        </p>

        <p><strong>Are estimates the total cost of repairs?</strong></p>

        <p>
          No, estimates are for labor costs and tools a contractor would be
          expected to have only. &nbsp;Parts, supplies and specialty tool
          rentals are not included in estimate pricing and should a contractor
          need to acquire these or assist in obtaining them estimates will most
          likely increase from initial prices.
        </p>

        <p><strong>How much is SuperService for customers?</strong></p>

        <p>Placing a service request on SuperService is free.</p>

        <p><strong>How much is SuperService for contractors?</strong></p>

        <p>
          Contractors are able to view pending service requests prior to payment
          to confirm there is adequate demand in the areas of work they would
          like to perform. &nbsp;Unlimited estimates may be submitted once the
          $5 monthly fee has been paid. &nbsp;
        </p>

        <p><strong>How does the contractor get paid?</strong></p>

        <p>
          Customers have options of preferred payment when submitting a service
          request and contractors can select the different forms of payment they
          are capable of accepting.
        </p>

        <p><strong>When does the contractor get paid?</strong></p>

        <p>
          As with any service industry of this nature payments should only be
          made once the work is completed.
        </p>
      </div>
    </template>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page.vue";

export default {
  name: "Faq",
  components: {
    Page,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>