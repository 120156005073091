// import ServiceService from '../../services/service.service';

// import Vue from 'vue'

import {
    MESSAGES_ADD,
    MESSAGES_EMPTY,
    MESSAGES_SHOWING
} from '../mutation-types'


export default {
    namespaced: true,

    state: {
        messages: [],
        showing: false
    },

    getters: {

        getMessages: (state) => {
            return state.messages;
        },

        getShowing: (state) => {
            return state.showing;
        },
    },

    actions: {

        // Creates new service
        async messageAdd({ commit }, message) {
            commit('MESSAGES_ADD', message);
            commit('MESSAGES_SHOWING', true);
        },

        // Creates new service
        async messageShowing({ commit }, flag) {
            commit('MESSAGES_SHOWING', flag);
        },

        // Delete service
        async messageEmpty({ commit }) {
            commit('MESSAGES_EMPTY');
        },
    },

    mutations: {

        [MESSAGES_ADD](state, message) {
            state.messages.push(message);
        },

        [MESSAGES_SHOWING](state, flag) {
            state.showing = flag;
        },

        [MESSAGES_EMPTY](state) {
            state.messages = [];
        },

    }
}