<template>
      <GmapMap
        v-if="location"
        :center="location"
        :zoom="zoom"
        :style="`width: 100%; height: ${mapHeight}`"
        ref="gmap"
      >
      </GmapMap>
</template>

<script>

import { getGoogleMapsAPI } from "gmap-vue";

export default {
  name: "Map",

  props: ['location', 'height'],

  components: {},

  data: () => ({
    map: null,
    marker: null,
    zoom: 14,
  }),

  computed: {

    mapHeight(){
      return (this.height ? this.height : "400") + "px";
    }
  },

  async created() {

    console.log("== Map::created ==")

  },

  async mounted() {

    // Loads the Google Map API
    await this.$gmapApiPromiseLazy();

    // Loads the Google Maps API (used to call geometry functions)
    this.google = getGoogleMapsAPI();


    // Wait until the map object is fully loaded
    this.$refs.gmap.$mapPromise.then((map) => {
      
      // Assigns the map in order to be used in the component
      this.map = map;

      // Starts with the rest of the mapping configuration.
      this.onMapLoaded();
    });

  },

  watch: { 
    location: function(newLocation) { 
      
      // Checks if the map was already created.
      if( this.marker && this.map){

        // Sets the marker in the new position
        this.marker.setPosition(newLocation);

        // Centers the map in the new position.
        this.map.setCenter(newLocation);
      }
  
    }
  },

  methods: {

    // Starts when the map is fully loaded
    onMapLoaded() {

      // Creates the marker
      this.marker = new this.google.maps.Marker({
        position: this.location,
      });

      // Assigns the marker to the map
      this.marker.setMap(this.map);

      // Sets the marker in the new position
      this.marker.setPosition(this.location);

      // Centers the map in the new position.
      this.map.setCenter(this.location);
    },
  },
};
</script>

<style>
</style>