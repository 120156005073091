<template>

  <v-dialog 
    persistent
    v-model="dialog" 
    :width="width ? width: '500'"
  >
    <v-card>
      <v-card-title class="headline">
        <slot name="title" v-if="this.$slots['title']" />
      </v-card-title>

      <v-card-text class="text-left">
        <slot name="text" v-if="this.$slots['text']" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="buttons" v-if="this.$slots['buttons']" />
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "Dialog",
  props: ["dialog", "width"],
  data: () => ({
  }),  
};
</script>