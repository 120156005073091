import dayjs from 'dayjs';

export default {

    methods: {

        helperGetDomain() {
            return process.env.VUE_APP_SERVER_ENDPOINT;
        },

        helperParseImagesResponse(images) {

            return images.split(",").map(element => (this.helperGetDomain() + element.trim()));
        },

        helperParseDate(date) {
            return dayjs(date).format('MMM DD [at] HH:MM');
        }

    }
}