<template>

  <v-card elevation="0">
   
    <v-card-title>
      Estimates
    </v-card-title>
    
    <v-card-text>
    
      <p v-if="!contractorEstimates || !contractorEstimates.length">
        No Jobs yet.
      </p>

      <v-list three-line v-if="contractorEstimates">
        <v-list-item-group>
          <template >
             
            <v-list-item
              v-for="(estimate, index) in contractorEstimates"
              :key="index"
              class="m-2"
              :to="`/job/${estimate.parent_job}/estimate/${estimate.nid}`"
            >
              <v-list-item-content>
                <v-list-item-title class="mb-2 text-body-1">
                  <strong>{{ estimate.amount }}</strong>
                </v-list-item-title>

                <v-list-item-subtitle class="mb-2 text-caption">
                  <v-icon small> mdi-calendar-clock </v-icon>
                   
                    {{ estimate.created }} 
                
                  <!-- {{ parseDateFormat(job.date) }} -->
                </v-list-item-subtitle>

                <v-list-item-content class="mb-2">
                  {{ estimate.details }}
                </v-list-item-content>
              </v-list-item-content>

            </v-list-item>
            
          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>

  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthService from '@/services/auth.service';
import Helper from "@/mixins/Helper";
import dayjs from "dayjs";

export default {
name: "CustomerJobList",

components: {},

mixins: [Helper],

data: () => ({
  domain: process.env.VUE_APP_SERVER_ENDPOINT,
  contractorEstimates: null
}),

computed:{
  ...mapGetters({
    getUser: "user/getUser",
  }),
},   

async created() {
  console.log("CustomerJobList::created()");
},

async mounted() {
  console.log("CustomerJobList::mounted()");

  this.setShowLoader(true);

  if(this.getUser){
   
    // Loads the customer jobs
    this.contractorEstimates = await AuthService.getContractorEstimates();

    // Updates the parent job values
    this.contractorEstimates.map(function(estimate){
      
      // Gets the parent id
      let parentJob = JSON.parse(estimate.parent_job);
      
      // Checks if value is not empty
      if(parentJob.length){
        estimate.parent_job = parentJob[0]['nid'];
      }

      return estimate;
    });

    console.log("== contractorEstimates ==");
    console.log(this.contractorEstimates);      
  }

  this.setShowLoader(false);
},

methods: {

  ...mapActions({
    setShowLoader: "main/setShowLoader",
  }),

  parseDatesString(dateString){
    let dates = dateString.split(",");

    for (let i = 0; i < dates.length; i++) {
      dates[i] = this.parseDateFormat(dates[i]);
    }

    return dates;
  },

  parseDateFormat(date) {
    return dayjs(date).format("MMM DD [at] HH:MM");
  },

  onClickJob(nid) {
    console.log("== onClickJob ==");
    console.log(nid);
  },
},
};
</script>

<style>
</style>