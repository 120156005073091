<template>
  <Page>
    <template v-slot:content>
      <v-container class="mt-2" fill-height fluid>
        <v-layout align-center justify-center>
          <v-flex xs8 sm8 md8 style="max-width: 500px">
            <h1 class="text-center my-5" >Today I am a...</h1>
            <v-row class="mt-5" >
              
              <v-col align-self="center">
                <router-link class="routerLink" to="/customer">
                  
                    <v-sheet
                      class="align-center justify-center d-flex flex-column"
                      style="margin: auto; background: #FFFF0B; color: #000000; font-weight: bold; text-transform: uppercase;"
                      color="FFFF0B"
                      height="200"
                      outlined
                      rounded
                      shaped
                      width="200"
                    >
                        Customer
                    </v-sheet>
                  
                </router-link>
              </v-col>
              <v-col>
                <router-link class="routerLink" to="/contractor">
                  
                    <v-sheet
                      class="align-center justify-center d-flex flex-column "
                      style="margin: auto; background: #0861CC; color: #ffffff; font-weight: bold; text-transform: uppercase;"
                      height="200"
                      outlined
                      rounded
                      shaped
                      width="200"
                    >
                      Contractor
                    </v-sheet>
                  
                </router-link>
              </v-col>              
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </Page>
</template>

<script>

import Page from "@/components/layout/Page.vue";

export default {
  name: "Home",
  components: {
    Page
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style>
.option{
  text-align: center;
  margin: 5px;
  background-color: azure;
}
.routerLink{
     text-decoration: none;
 }
</style>