<template>
  <div>
    <form @submit.prevent="submit">
      <v-card elevation="0" >
        <v-card-title>Create new account</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="user.email"
            :error-messages="emailErrors"
            label="Email"
            required
            @input="$v.user.email.$touch()"
            @blur="$v.user.email.$touch()"
          ></v-text-field>

          <v-text-field
            v-model="user.password"
            :error-messages="passwordErrors"
            label="Password"
            required
            type="password"
            @input="$v.user.password.$touch()"
            @blur="$v.user.password.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="submit" depressed>Register</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

import { mapActions } from "vuex";

import AuthService from '@/services/auth.service';

export default {
  name: "Register",
  components: {

  },
    data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
    },
  },
  async created() {
    console.log("Login::created");
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  computed: {
    emailErrors() {
      // List of errors
      const errors = [];

      // If the input has not been touched return
      if (!this.$v.user.email.$dirty) return errors;

      // List of validations
      !this.$v.user.email.email && errors.push("Valid email required");

      !this.$v.user.email.required && errors.push("Email is required");

      return errors;
    },
    passwordErrors() {
      const errors = [];

      // If the input has not been touched return
      if (!this.$v.user.password.$dirty) return errors;

      // List of validations
      !this.$v.user.password.required && errors.push("Password is required");

      // !this.$v.user.password.minLength && errors.push("Contraseña debe tener al menos 8 caracteres");

      return errors;
    },
  },
  methods: {

    ...mapActions({
      userRegister: "user/register",
      userLogin: "user/login",
    }),

    async submit() {
      
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (this.user.email && this.user.password) {
        
        // Gets the token to POST
        const token = await AuthService.token();

        // Register payload
        let payload = {
          token: token,
          name: {
            value: this.user.email
          },
          pass: {
            value:this.user.password 
          },
          mail: {
            value: this.user.email
          }
        }

        // Call the register service
        let response = await this.userRegister(payload);

        // Checks register was good
        if(response.status){

          // Calls the login service if the register worked fine.
          let responseLogin = await this.userLogin({
            name: this.user.email,
            pass: this.user.password,
          });

          // If the login was successfully it will redirect the user 
          // to the main page
          if(responseLogin.status){
            this.$router.push("/customer");
          }else{
            console.log("Login Error: " + response.message);
          }
          
        }else{
          console.log("Register Error: " + response.message);
        }
      }
    },
  },
};
</script>

<style>
</style>