<template>
  <v-snackbar 
      :value="this.snackbar.show"
      @input="hideSnackbar"
      :multi-line="true"
  >
    {{ this.snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="red"
        text
        v-bind="attrs"
        @click="hideSnackbar"
      >
        Close
      </v-btn>
    </template>  
  </v-snackbar>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {

  name: "Snackbar",

  data() {
    return {
      show: false,
    };
  },  

  computed:{
    ...mapGetters({
      snackbar: "main/getSnackbar",
    }),
  },

  methods: {

    ...mapActions({
      setSnackbar: "main/setSnackbar",
    }),

    async hideSnackbar(){

      // Updates the status in the store
      await this.setSnackbar(
        {
          show: false,
          text: ""
        }
      );
    }
  }
};
</script>
<style scoped>
</style>