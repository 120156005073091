<template>
     <v-card elevation="0">

      <v-card-title>
        Edit
      </v-card-title>
      <v-card-subtitle>
        Edit personal information
      </v-card-subtitle>      
      <v-card-text class="text-center">

        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="formData.firstname"
              :counter="100"
              label="First Name"
              required
              :error-messages="firstNameErrors"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="formData.lastname"
              :counter="100"
              label="Last Name"
              required
              :error-messages="lastNameErrors"
            ></v-text-field>
          </v-col>          
        </v-row>
        
        <v-text-field
          id="place"
          v-model="formData.place"
          label="Location"
          required
          @input="$v.formData.place.$touch()"
          @blur="$v.formData.place.$touch()"                 
          :error-messages="placeErrors"
        ></v-text-field>

        <v-file-input
          v-model="formData.image"
          prepend-icon=""
          prepend-inner-icon="mdi-paperclip"
          label="Profile image"
        ></v-file-input>        
      </v-card-text>
      <v-card-actions class="justify-center" >
          <v-btn text @click="submit"> Submit </v-btn>
      </v-card-actions>
     </v-card>
</template>

<script>

import { required} from 'vuelidate/lib/validators'
import { validationMixin } from "vuelidate";
import { mapActions, mapGetters } from "vuex";
import AuthService from "@/services/auth.service";
import { getGoogleMapsAPI } from "gmap-vue";


export default {

  name: "AccountEdit",

  components: {
    // Map
  },

  mixins: [validationMixin],

  data: () => ({
    google: null,
    map: null,    
    formData:{
      firstname: null,
      lastname: null,
      image: null,
      place: null,
      lat: null,
      lng: null,
    }
  }), 
  
  validations: {

    formData: {

      firstname: {
        required
      },

      lastname:{
        required     
      } ,

      place: { 
        required
      },        

    }
  },   

  computed: {

    ...mapGetters({
      getUser: "user/getUser",
      getUserInfo: "user/getInfo",
    }),

    firstNameErrors() {
      const errors = [];
      if (!this.$v.formData.firstname.$dirty) return errors;
      !this.$v.formData.firstname.required && errors.push("Firstname is required.");
      return errors;
    },

    lastNameErrors() {
      const errors = [];
      if (!this.$v.formData.lastname.$dirty) return errors;
      !this.$v.formData.lastname.required && errors.push("Lastname is required.");
      return errors;
    },   

    placeErrors() {
      const errors = [];
      if (!this.$v.formData.place.$dirty) return errors;
      !this.$v.formData.place.required && errors.push("Location is required.");
      return errors;
    },       
  },

  async created() {
    console.log("Account::created");
  },  

  async mounted() {
    console.log("Account::mounted");
    // console.log(this.getUserInfo);

    if(this.getUserInfo.first_name){
      this.formData.firstname = this.getUserInfo.first_name;
    }

    if(this.getUserInfo.last_name){
      this.formData.lastname = this.getUserInfo.last_name;
    }

    if(this.getUserInfo.place){
      this.formData.place = this.getUserInfo.place;
    }

    if(this.getUserInfo.location){
      let location = JSON.parse(this.getUserInfo.location)
      this.formData.lat = location.lat;
      this.formData.lng = location.lng;
    }

    // Loads the Google Map API
    await this.$gmapApiPromiseLazy();

    // Loads the Google Maps API (used to call geometry functions)
    this.google = getGoogleMapsAPI();

    this.onMapLoaded();

    // // Wait until the map object is fully loaded
    // this.$refs.gmap.$mapPromise.then((map) => {
    //   // Assigns the map in order to be used in the component
    //   this.map = map;

    //   // Starts with the rest of the mapping configuration.
    //   this.onMapLoaded();
    // });    
  },
  methods: {

    ...mapActions({
      setUserInfo: "user/setUserInfo",
      userLoadInfo: "user/loadInfo",
      setShowLoader: "main/setShowLoader",
    }),

    onMapLoaded() {

      var that = this;
      // Input
      var input = document.getElementById("place");

      // Filter options
      var options = {
        types: ["(regions)"],
        componentRestrictions: { country: "us" },
      };

      // Gets the autocomplement object
      var autocomplete = new this.google.maps.places.Autocomplete(
        input,
        options
      );

      // The autocomplete input listen the changes when the user types
      this.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          // Gets the place
          var place = autocomplete.getPlace();

          // Updates the input field with the place name
          that.formData.place = place.formatted_address;

          that.formData.lat = place.geometry.location.lat();
          that.formData.lng = place.geometry.location.lng();
        }
      );
    },    
    
    async submit(){
      console.log("== AccountEdit::submit() ==")

      this.setShowLoader(true);

      const image = this.formData.image;

      let payload = {};

      payload.field_first_name = {
       "value": this.formData.firstname
      }

      payload.field_last_name = {
        "value": this.formData.lastname
      }

      if(image){
        let binaryImage = await AuthService.loadImageFile(image);
        let fid = await AuthService.uploadImage(binaryImage);
        payload.user_picture = {
            "target_id": fid
        }    
      }

      payload.field_place = {
       "value": this.formData.place
      }

      payload.field_location = {
        "value": {
          "lat": this.formData.lat,
          "lng": this.formData.lng,
        }
      }

      const updatedUserInfo = await AuthService.updateUserInfo(this.getUser.current_user.uid, payload);
    
      // Loads the user account information
      await this.setUserInfo(updatedUserInfo);

      await this.userLoadInfo();

      this.setShowLoader(false);

      this.$router.push("/account");

    }
  }  
};
</script>

<style>
</style>