<template>

    <v-card elevation="0">
     <v-card-title class="d-flex justify-space-between">
        <div>
          My Jobs
        </div>
        <div>

          <v-btn 
            plain
            color="primary"
            @click="onClickViewMode('new')" 
            x-small               
          >
            <v-icon small>mdi-new-box</v-icon> new
          </v-btn>

          <v-btn 
            plain
            color="primary"
            @click="onClickViewMode('accepted')" 
            x-small
          >
            <v-icon small>mdi-progress-clock</v-icon> accepted
          </v-btn>

          <v-btn 
            plain
            color="primary"
            @click="onClickViewMode('completed')" 
            x-small
          >
            <v-icon small>mdi-check-bold</v-icon> completed
          </v-btn>
        </div>
    </v-card-title>

      <v-card-text>
      
        <p v-if="!customerJobs || !customerJobs.length">
          No Jobs yet.
        </p>

        <v-list three-line v-if="customerJobs">
          <v-list-item-group>
            <template >
              <v-list-item
              v-for="(job, index) in customerJobs"
                :key="index"
                class="m-2"
                :to="{ name: 'CustomerJobDetail', params: { job_id: job.nid } }"
              >

                <v-list-item-content>
                  <v-list-item-title class="mb-2 text-body-1">
                    <strong>{{ job.category_name }}</strong>
                  </v-list-item-title>

                  <v-list-item-subtitle class="mb-2 text-caption">
                    <v-icon small> mdi-calendar-clock </v-icon>
                     <template v-for="(date) in parseDatesString(job.dates)">
                      {{ date }} 
                     </template>
                    <!-- {{ parseDateFormat(job.date) }} -->
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="mb-2 text-caption">
                    <v-icon small> mdi-map-marker </v-icon>
                    {{ job.place.substr(0, 50) }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="mb-2">
                    {{ job.details.substr(0, 100) }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text class="font-weight-bold">{{
                    job.payment_type
                  }}</v-list-item-action-text>

                  <v-list-item-action-text>{{
                    job.estimate_type
                  }}</v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>

    </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthService from '@/services/auth.service';
import Helper from "@/mixins/Helper";
import dayjs from "dayjs";

export default {
  name: "CustomerJobList",

  components: {},

  mixins: [Helper],

  data: () => ({
    domain: process.env.VUE_APP_SERVER_ENDPOINT,


    customerJobs: null,

    // new, accepted, complete
    viewMode: 'new',
  }),

  computed:{
    ...mapGetters({
      getUser: "user/getUser",
    }),

  },   

  async created() {
    console.log("CustomerJobList::created()");
  },

  async mounted() {
    console.log("CustomerJobList::mounted()");

    this.setShowLoader(true);

    console.log(this.getUser);

    if(this.getUser){
     
      await this.getJobs('new');

      console.log("== customerJobs ==");
      console.log(this.customerJobs);      
    }

    this.setShowLoader(false);
  },

  watch: { 
    viewMode: function(newView) { 
      
      this.getJobs(newView);
    }
  },

  methods: {

    ...mapActions({
      setShowLoader: "main/setShowLoader",
    }),

    async getJobs(status){
      // Loads the customer jobs
      this.customerJobs = await AuthService.getJobs(status);
    },

    onClickViewMode(mode){
      this.viewMode = mode;
    },

    parseDatesString(dateString){
      let dates = dateString.split(",");

      for (let i = 0; i < dates.length; i++) {
        dates[i] = this.parseDateFormat(dates[i]);
      }

      return dates;
    },

    parseDateFormat(date) {
      return dayjs(date).format("MMM DD [at] HH:MM");
    },

    onClickJob(nid) {
      console.log("== onClickJob ==");
      console.log(nid);
    },
  },
};
</script>

<style>
</style>