<template>
  <div class="text-center ma-2q" style="text-align: center">
    <h3 class="text-uppercase">
      {{ this.imageName }}
    </h3>
    <!-- <v-img
      v-if="this.image"
      style="margin:auto"
      :src="theImagePath"
      width="100%"
      max-width="180"
    ></v-img> -->
    <img 
      :src="theImagePath"
      style="max-width: 100%"
    />
  </div>
  <!-- <v-sheet
    class="sheet align-center justify-center d-flex flex-column px-2 mx-2 my-2"
    :color="selected? '#8da8c3' : '#ebf0f5'"
    outlined
    rounded
    shaped
    width="100%"
    max-width="300"
    height="100%"
    max-height="300"
    :id="this.image"
  >
    <v-icon>{{ icon }}</v-icon> 
    
    <span style="background-color: white ;"><strong>{{ content }}</strong></span>
  </v-sheet> -->
</template>

<script>
export default {
  name: "Category",
  components: {},
  props: ["icon", "content", "selected", "image"],
  data() {
    return {
      imagePath: "",
      imageName: "",
    };
  },

  created() {
    console.log('image', this.image)
    switch(this.image){
      case "auto": 
        this.imagePath = "../assets/auto.png";
        this.imageName = "Auto";
      break;
      case "home": 
        this.imagePath = "../assets/home.png";
        this.imageName = "Home";
      break;
      case "industrial": 
        this.imagePath = "../assets/industrial.png";
        this.imageName = "Industrial";
      break;
      case "other": 
        this.imagePath = "../assets/other.png";
        this.imageName = "Other";
      break;
      case "yard": 
        this.imagePath = "../assets/yard.png";
        this.imageName = "Yard";
      break;
    }
  },

  mounted() {

  },
  computed: {
    theImagePath(){
        return require(`../assets/${this.image}.png`);
    }
  },
  methods: {
    submit() {},
  },
};
</script>

<style>



</style>