import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import status_message from './modules/status_message'
import main from './modules/main'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user: user,
        status_message,
        main
    }
})