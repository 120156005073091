<template>
  <v-container class="mt-2" fill-height fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md8 style="max-width: 600px">

        <v-img
          max-width="300px"
          src="@/assets/logo3.png"
          class="mx-auto mt-10 mb-9"
        ></v-img>

        <v-tabs v-model="tab" centered background-color="transparent">
          <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->
          <v-tab href="#tab-1">Login</v-tab>
          <v-tab href="#tab-2">Register</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" background-color="transparent">
          <v-tab-item value="tab-1" >
                <Login></Login>
          </v-tab-item>
          <v-tab-item value="tab-2"> 
            <Register></Register>
             </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

export default {
  name: "Auth",
  components: {
    Login,
    Register
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {

  },
};
</script>

<style>
</style>