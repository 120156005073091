<template>
  <Page>
    <template v-slot:title> Contact </template>
    
    <template v-slot:content>
      <div>
        <p>Soon.</p>
      </div>
    </template>
  </Page>
</template>

<script>

import Page from "@/components/layout/Page.vue";

export default {
  name: "Contact",
  components: {
    Page
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style>
</style>