<template>
     <v-card elevation="0">

      <v-card-text class="text-center">
        <v-avatar size="200" class="mt-4 mb-2" v-if="getUserInfo.user_picture">
          <img
            :src="helperGetDomain() + '/' + getUserInfo.user_picture"
            style="width: 100%; 100%; object-fit: cover;"
          >
        </v-avatar>

        <div class="text-h5" v-if="getUserInfo.first_name && getUserInfo.last_name">
          {{getUserInfo.first_name}} {{getUserInfo.last_name}}
        </div>

        <div class="text-subtitle-2" v-if="getUserInfo.name">
          {{getUserInfo.name}}
        </div>

        <div class="mt-5 font-weight-bold" v-if="getUserInfo.place">
          {{getUserInfo.place}}
        </div>

        <div class="mt-3" v-if="getUserInfo.location">
          <Map 
            :location="JSON.parse(getUserInfo.location)"
            :height="300"
          ></Map>
        </div>
      </v-card-text>
     </v-card>
</template>

<script>

import { /*mapActions,*/ mapGetters } from "vuex";
import Helper from "@/mixins/Helper";
import Map from "@/components/Map.vue";

export default {

  name: "AccountIndex",

  components: {
    Map
  },

  mixins: [Helper],

  data: () => ({


  }),  
  computed: {
    ...mapGetters({
      getUser: "user/getUser",
      getUserInfo: "user/getInfo",
    }),
  },
  async created() {
    console.log("Account::created");
  },  
  async mounted() {
    console.log("Account::mounted");
    // console.log(JSON.parse(this.getUserInfo.location));
  },
  methods: {
    // ...mapActions({
    //   userLogin: "user/login",
    //   userLoadInfo: "user/loadInfo",
    // }),
  }  
};
</script>

<style>
</style>