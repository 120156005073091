<template>
  <div>
    
    <form @submit.prevent="submit">
      <v-card elevation="0">
        <v-card-title>Login into your account</v-card-title>
        <v-card-text>

          <!-- EMAIL -->
          <v-text-field
            v-model="user.email"
            :error-messages="emailErrors"
            label="Email"
            required
            @input="$v.user.email.$touch()"
            @blur="$v.user.email.$touch()"
          ></v-text-field>

          <!-- PASSWORD -->
          <v-text-field
            v-model="user.password"
            :error-messages="passwordErrors"
            label="Password"
            required
            type="password"
            @input="$v.user.password.$touch()"
            @blur="$v.user.password.$touch()"
          ></v-text-field>

        </v-card-text>
        <v-card-actions class="justify-center">
             <v-btn @click="submit" depressed>Login</v-btn>
        </v-card-actions>
      </v-card>
    </form>

    <!-- <Loader
      :show="showLoader"
    /> -->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import AuthService from "@/services/auth.service";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      showLoader: false,
      user: {
        email: "",
        password: "",
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
    },
  },
  created() {
    console.log("Login::created");
  },
  computed: {
    ...mapGetters({
      getUser: "user/getUser",
    }),

    emailErrors() {
      // List of errors
      const errors = [];

      // If the input has not been touched return
      if (!this.$v.user.email.$dirty) return errors;

      // List of validations
      !this.$v.user.email.email && errors.push("Valid email required");

      !this.$v.user.email.required && errors.push("Email is required");

      return errors;
    },
    passwordErrors() {
      const errors = [];

      // If the input has not been touched return
      if (!this.$v.user.password.$dirty) return errors;

      // List of validations
      !this.$v.user.password.required && errors.push("Password is required");

      // !this.$v.user.password.minLength && errors.push("Contraseña debe tener al menos 8 caracteres");

      return errors;
    },
  },
  methods: {
    ...mapActions({
      // userLogin: "user/login",
      setUser: "user/setUser",
      userLoadInfo: "user/loadInfo",
      setShowLoader: "main/setShowLoader",
    }),

    async submit() {

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.setShowLoader(true);

      if (this.user.email && this.user.password) {
        
        let payload = {
          name: this.user.email,
          pass: this.user.password,
        };

        try {

          let responseUserLogin = await AuthService.login(payload);

          await this.setUser(responseUserLogin);   

          // Loads the user account information
          await this.userLoadInfo();          

          this.setShowLoader(false);

          // Redirects the user to the main page
          this.$router.push("/");

        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
</style>  