<template>
  <v-container class="mt-2">

    <Header></Header>

    <!-- Header -->
    <div class="d-flex justify-space-between">
      <div class="mb-7">
        <h1>
            <!-- Title -->
            <slot name="title" v-if="this.$slots['title']" />
        </h1>
        <!-- Breadcrumbs -->
         <slot name="breadcrumbs" v-if="this.$slots['breadcrumbs']" />
      </div>
      <div class="mb-2">
        <!-- Menu -->
        <slot name="menu" v-if="this.$slots['menu']" />
      </div>
    </div>

    <!-- Status messages -->
    <transition name="fade-transition">
      <StatusMessage :messages="['This is a test', 'This is another test']" v-if="messageIsShowing" />
    </transition>  

    <!-- Content -->
    <slot name="content" v-if="this.$slots['content']" />

    <!-- Dialog -->
    <slot name="dialog" v-if="this.$slots['dialog']" />

    <!-- Loader -->
    <!-- <slot name="loader" v-if="this.$slots['loader']" />     -->

    <!-- Snackbar -->
    <slot name="snackbar" v-if="this.$slots['snackbar']" />    
 </v-container>
</template>

<script>
import StatusMessage from "@/components/StatusMessage.vue";
import { /*mapActions, */mapGetters } from "vuex";

import Header from "@/components/layout/Header";

export default {
  name: "Page",
  
  components: {
    StatusMessage,
    Header
  },  
  computed:{

    ...mapGetters({
      getMessages: "status_message/getMessages",
      getShowing: "status_message/getShowing",
    }),

    messages(){
      return this.getMessages;
    },

    messageIsShowing(){
      return this.getShowing;
    },
  },
  data: () => ({
    // messages: [
    //   "aaaa",
    //   "bbbb"
    // ]
  }),
};
</script>
<style scoped>
h1{
  font-size: 28px;
  color: #515151;
}
</style>