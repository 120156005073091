<template>
  <Page>
    <!-- <template v-slot:title> Account </template> -->
    <template v-slot:menu>

        <v-btn 
          plain
          color="primary"
          to="/account/edit" 
          exact
        >
          <v-icon>mdi-tools</v-icon> Edit
        </v-btn>        
    </template>     
    <template v-slot:content>
      <router-view></router-view>
    </template>
  </Page>
</template>

<script>

import Page from "@/components/layout/Page.vue";

export default {
  name: "AccountIndex",
  components: {
    Page
  },
};
</script>

<style>
</style>