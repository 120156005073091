<template>
  <div>
    <v-card elevation="0" v-if="this.job && this.estimate">
      <v-card-title class="d-flex justify-space-between">
        <div>
          <div style="font-size: 12px" class="ma-0 pa-0">
            <router-link class="routerLink" :to="`/job/${this.job.nid}`">Back to the job</router-link></div>
          Estimate of the job {{ job.category }} at {{ job.place }}
        </div>
        <div>
        </div>
      </v-card-title>
     <v-card-subtitle>
        By <strong>{{ estimate.first_name }} {{ estimate.last_name }}</strong>
        <div>Amount: <strong>${{ estimate.amount }}</strong> </div>
      </v-card-subtitle>
      <v-card-text >
        {{ estimate.details }}
      </v-card-text>
      <v-card-actions class="justify-center pb-0 py-5">
        <span
          v-if="getUser.current_user.uid == job.uid && this.job.estimate_status == 'new'"
        >
          Accept this estimate
        </span>
        <span
          v-if="getUser.current_user.uid == job.uid && this.job.estimate_status == 'accepted' && this.job.accepted_estimate == this.estimate.nid" 
        >
          Complete this estimate
        </span>
        <span
          v-if="getUser.current_user.uid == job.uid && this.job.estimate_status == 'completed' && this.job.accepted_estimate == this.estimate.nid" 
        >
          The estimation was completed, please rate it.
        </span>
      </v-card-actions>
      <v-card-actions class="justify-center pb-5">
        <v-btn 
          v-if="getUser.current_user.uid == job.uid && this.job.estimate_status == 'new'"
          color="primary" 
          text 
          @click="estimateDialog = true"
        > 
          Accept
        </v-btn>

        <v-btn 
          v-if="getUser.current_user.uid == job.uid && this.job.estimate_status == 'accepted' && this.job.accepted_estimate == this.estimate.nid" 
          color="primary" 
          text 
          @click="finishDialog = true"
        >
          Complete
        </v-btn>
      </v-card-actions>

      <!-- RATING -->
      <v-card-text 
        v-if="getUser.current_user.uid == job.uid && this.job.estimate_status == 'completed' && this.job.accepted_estimate == this.estimate.nid" 
      >
          <div
            class="text-center"
            style="max-width: 480px; margin: auto;"
          >
            <v-rating
              class="mb-2"
              v-model="formRatingData.rating"
              background-color="blue lighten-3"
              color="blue"
              large
            ></v-rating>
            <v-textarea
              name="description"
              filled
              label="Please include details"
              auto-grow
              value=""
              required
              v-model="formRatingData.description"
              @input="$v.formRatingData.description.$touch()"
              @blur="$v.formRatingData.description.$touch()"
              :error-messages="descriptionErrors"
            ></v-textarea>

            <v-btn 
              color="primary" 
              text 
              @click="ratingDialog = true"
            >
            Rate
          </v-btn>
          </div>

      </v-card-text>
    </v-card>
    
    <!-- ESTIMATE DIALOG -->
    <Dialog v-if="estimateDialog" :dialog="estimateDialog">
      <template v-slot:title>Accept this estimate</template>
      <template v-slot:text>
        Do you want to accept this estimate?
      </template>
      <template v-slot:buttons>
        
        <v-btn color="primary" text @click="estimateDialog = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="acceptEstimate"> 
          Accept estimate 
        </v-btn>

      </template>
    </Dialog>

    <!-- FINISH DIALOG -->
    <Dialog v-if="finishDialog" :dialog="finishDialog">
      <template v-slot:title>Finish this estimate</template>
      <template v-slot:text>
        Do you want to finish and close this estimate?
      </template>
      <template v-slot:buttons>
  
        <v-btn color="primary" text @click="finishDialog = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="finishEstimate"> 
          Finish estimate 
        </v-btn>

      </template>
    </Dialog>

    <!-- RATING DIALOG -->
    <Dialog v-if="ratingDialog" :dialog="ratingDialog">
      <template v-slot:title>Rate this estimate</template>
      <template v-slot:text>
        Do you want to rate this estimate?
      </template>
      <template v-slot:buttons>
  
        <v-btn color="primary" text @click="ratingDialog = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="rateEstimate"> 
          Rate estimate 
        </v-btn>

      </template>
    </Dialog>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";
import Helper from "@/mixins/Helper";
import { mapGetters, mapActions } from "vuex";
import Dialog from "@/components/Dialog.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EstimateDetail",
  components: { Dialog },

  mixins: [Helper, validationMixin],

  data() {
    return {
      job: null,
      estimate: null,
      estimateDialog: false,
      finishDialog: false,
      ratingDialog: false,
      formRatingData: {
        description: "",
        rating: 1,
      },
    };
  },

  validations: {
    formRatingData: {
      description: {
        required,
      },
    },
  },


  computed: {
    ...mapGetters({
      getUser: "user/getUser",
    }),

    descriptionErrors() {
      const errors = [];
      
      if (!this.$v.formRatingData.description.$dirty) {
        return errors;
      }

      if(!this.$v.formRatingData.description.required){
        errors.push("The description is required.");
      }
        
      return errors;
    },

    estimateDetail(){
      return this.job.estimates.find(estimate => estimate.nid == this.clickedEstimateId );
    },
    
  },

  methods: {
    ...mapActions({
      // setShowLoader: "main/setShowLoader",
    }),

    // Opens dialog to accept estimate
    openEstimateDialog() {
      this.estimateDialog = true;
    },

    // Sends request to accept estimate
    async acceptEstimate(){
      console.log("== acceptEstimate ==");

      const payload = {
        jid: this.job.nid,
        eid: this.estimate.nid,
      };

      const acceptedEstimate = await AuthService.postAcceptEstimate(payload);
      console.log("acceptedEstimate", acceptedEstimate);

      if (acceptedEstimate.status) {
        this.job.estimate_status = acceptedEstimate.status;
        this.job.accepted_estimate = acceptedEstimate.accepted_estimate;
      }
      
      this.estimateDialog = false
    },

    // Sends request to finish the estimate
    async finishEstimate(){
      console.log("== finishEstimate ==");

      const payload = {
        jid: this.job.nid,
        completed: true,
      };

      const completedEstimate = await AuthService.postCompleteEstimate(payload);
      console.log("completedEstimate", completedEstimate);

      if (completedEstimate.status) {
        this.job.estimate_status = completedEstimate.status;
        this.job.accepted_estimate = completedEstimate.accepted_estimate;
      }
      
      this.finishDialog = false;
    },

    // Sends request to rate the estimate
    async rateEstimate(){
      console.log("== rateEstimate ==");
      console.log(this.formRatingData);

      const payload = {
        eid: this.estimate.nid,
        description: this.formRatingData.description,
        rating: this.formRatingData.rating,
      };

      const ratedEstimate = await AuthService.postRateEstimate(payload);
      console.log("ratedEstimate", ratedEstimate);

      this.ratingDialog = false
    },
  },

  async mounted() {
    console.log("Mount::EstimateDetail");

    // First checks if the user is logged in
    if(this.getUser){

      // Gets the job id from the url
      const jobId = this.$route.params.job_id

      // Loads the job
      this.job = await AuthService.getCustomerJobDetail(jobId);
      
      // If the estimate is because the user is accessing the view
      // is the owner of the job or the owner of the estimate
      if (this.job.estimate_status) {

        // Parses the estimate status from a string format
        let jobEstimateStatusParsed = JSON.parse(this.job.estimate_status);

        // It checks the response has at least one status
        if(jobEstimateStatusParsed.length){

          // Parses the estimate and status
          this.job.estimate_status = jobEstimateStatusParsed[0]['status'];
          this.job.accepted_estimate = jobEstimateStatusParsed[0]['accepted_estimate'];
        }
      }
      // console.log("job", this.job);

      // Gets the estimate id from the url
      const estimateId = this.$route.params.estimate_id;

      // Gets the estimate by estimateId
      this.estimate = await AuthService.getEstimate(estimateId);

      // Parses the estimate rating and update the form rating value
      this.formRatingData.rating = parseInt(this.estimate.rating_value);

      // Updates the form rating description 
      this.formRatingData.description = this.estimate.rating_description;
      console.log("estimate", this.estimate);
    }
  },
};
</script>

<style>
</style>