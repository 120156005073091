<template>
  <div>
    <div v-show="e1 == 1">
      <h1 class="text-center mb-4 text-uppercase">I need help with</h1>
      <v-item-group
        class="d-flex justify-center flex-wrap"
        v-model="formData.category.id"
      >

          <div class="row-category">
            <template 
                v-for="(category, i) in categories" 
              >
              <div
                :key="i"
                v-if="!category.parent"
                class="text-center column-category ma-2"
              >
                <v-item
                  v-slot="{ active, toggle }"
                  :value="category.tid"
                >
                  <Category
                    @click.native="
                      toggle();
                      getSubCategory(category.tid);
                    "
                    :icon="category.icon"
                    :image="categoriesImages[i]"
                    :content="category.name"
                    :selected="active"
                  ></Category>
                </v-item>
              </div>
            </template>
          </div>
<!-- 
          <v-container>
            <v-row align-content="center" justify="center">
              <template 
                v-for="(category, i) in categories" 
              >
              <v-col 
                :key="i"
                v-if="!category.parent"
                class="text-center"
              >
                <v-item
                  v-slot="{ active, toggle }"
                  :value="category.tid"
                >
                  <Category
                    @click.native="
                      toggle();
                      getSubCategory(category.tid);
                    "
                    :icon="category.icon"
                    :image="categoriesImages[i]"
                    :content="category.name"
                    :selected="active"
                  ></Category>
                </v-item>
              </v-col>
            </template>
            </v-row>
          </v-container> -->
      </v-item-group>

      <!-- SUB CATEGORIES -->
      <div v-if="this.subCategories">
        <v-container>
          <v-row>
            <v-col
              v-for="(subCategory, ii) in this.subCategories"
              :key="ii"
              xs="12"
              sm="6"
              lg="3"
            >
              <v-list>
                <!-- <v-list-group
                  :value="true"
                  :prepend-icon="subCategory.icon ? subCategory.icon : 'mdi-arrow-right-bold-hexagon-outline'"
                  color="red"
                > -->
                <v-list-group color="blue">
                  <template v-slot:activator>
                    <v-list-item-title>{{
                      subCategory.name
                    }}</v-list-item-title>
                  </template>

                  <v-list-item-group>
                    <v-list-item
                      v-for="(subSubCategory, iii) in getSubSubCategory(
                        subCategory.tid
                      )"
                      :key="iii"
                      link
                      @click="
                        onSubSubCategoryClicked(subSubCategory.tid)
                      "
                    >
                      <v-list-item-icon>
                        <v-icon v-if="subSubCategory.icon">{{
                          subSubCategory.icon
                        }}</v-icon>
                        <v-icon v-else
                          >mdi-arrow-right-bold-hexagon-outline</v-icon
                        >
                      </v-list-item-icon>

                      <v-list-item-title>{{
                        subSubCategory.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list-group>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="text-center mt-12">
        <v-btn
          :disabled="formData.category.id == null"
          color="primary"
          @click="e1 = 2"
        >
          Continue
        </v-btn>
      </div>
    </div>

    <div v-show="e1 == 2">
      <v-container>
        <v-row>
          <v-col 
            cols="12" sm="12" md="4" >
            <v-card style="height: 100%;">
              <v-card-title>
                Specifications
              </v-card-title>

              <v-card-text>
                <h4 class="mb-4">Description</h4>
                <v-textarea
                  name="input-7-1"
                  filled
                  label="Description of the job"
                  auto-grow
                  value=""
                  v-model="formData.details.description"
                ></v-textarea>

                <h4 class="mb-4">Job images</h4>
                <v-file-input
                  v-model="formData.details.images"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  multiple
                  label="File input"
                ></v-file-input>

                <div v-for="(date, i) in formData.date.dates" :key="i">
                  <DateTimePicker
                    :index="i"
                    :close-on-content-click="true"
                    @updatedDateValues="updatedDateValues"
                  ></DateTimePicker>
                </div>
                <v-btn
                  class="elevation-0 mr-2"
                  @click="clickAddDate"
                  small
                  :disabled="this.formData.date.dates.length >= 10"
                  >Add Date</v-btn
                >
                <v-btn
                  class="elevation-0"
                  @click="clickRemoveDate"
                  small
                  :disabled="this.formData.date.dates.length <= 1"
                  >Remove Date</v-btn
                >
              </v-card-text>
            </v-card>

          </v-col>
          <v-col cols="12" sm="12" md="4" >
            <v-card style="height: 100%;">
              <v-card-title>
                Payment & Contact
              </v-card-title>
              <v-card-text>
                <h4 class="mb-4">Payment Method</h4>
                <!-- Payment -->
                <div class="mt-5"><strong>How will be able to pay</strong></div>
                <v-checkbox
                  v-model="formData.details.payment"
                  label="Cash"
                  value="cash"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="formData.details.payment"
                  label="Card"
                  value="card"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="formData.details.payment"
                  label="Paypal"
                  value="paypal"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="formData.details.payment"
                  label="Other"
                  value="other"
                  hide-details
                ></v-checkbox>

                <div
                  class="custom-input-error"
                  v-show="!formData.details.payment.length"
                >
                  Payment type is required
                </div>

                <h4 class="mb-4">Estimate Type</h4>
                <v-radio-group v-model="formData.details.estimate" mandatory>
                  <v-radio label="Labor only estimate" value="labor"></v-radio>
                  <v-radio label="Whole job estimate" value="whole"></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-card style="height: 100%;">
              <v-card-title>
                Location
              </v-card-title>
              <v-card-text>
                <h4 class="mb-4">Location</h4>
                <v-text-field
                  id="place"
                  v-model="formData.location.place"
                  label="Location"
                  required
                  @input="$v.formData.location.place.$touch()"
                  @blur="$v.formData.location.place.$touch()"
                  :error-messages="locationPlaceErrors"
                ></v-text-field>

                <Map :location="mapLocation" :height="400"></Map>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="text-center mt-12">
        <v-btn text @click="e1 = 1"> Back </v-btn>
        <v-btn color="primary" @click="submit">Submit Service Request</v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import Category from "@/components/Category";
import { getGoogleMapsAPI } from "gmap-vue";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Helper from "@/mixins/Helper";
import dayjs from "dayjs";
import AuthService from "@/services/auth.service";
import Map from "@/components/Map.vue";
import DateTimePicker from "@/components/DateTimePicker";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomerNewJob",
  components: {
    Category,
    Map,
    DateTimePicker,
  },

  mixins: [validationMixin, Helper],

  data() {
    return {
      e1: 1,

      google: null,

      map: null,

      marker: null,

      selected: null,

      // Center of the map
      center: {
        lat: 35.1175,
        lng: -89.971107,
      },

      // Default zoom
      zoom: 14,

      // radios: null,
      checkboxes: [],

      categoriesImages: [
         "auto",
         "home",
         "industrial",
         "other",
         "yard",
      ],

      formData: {
        category: {
          _name: "Category",
          _step: 1,
          _icon: "mdi-view-grid",
          id: null,
          subId: null,
        },
        details: {
          _name: "Details",
          _step: 2,
          _icon: "mdi-account-details",
          description: null,
          payment: [],
          estimate: null,
          images: null,
        },
        location: {
          _name: "Location",
          _step: 3,
          _icon: "mdi-map-marker",
          place: null,
          lat: null,
          lng: null,
        },
        date: {
          _name: "Date",
          _step: 4,
          _icon: "mdi-calendar-clock",
          time: null,
          dates: [{ date: null, time: null }],
        },
      },

      categories: null,

      subCategories: null,

      subSubCategories: null,

      selectedCategory: 0,
    };
  },

  validations: {
    formData: {
      // details: {
      //   description: {
      //     required,
      //   },
      // },
      location: {
        place: {
          required,
        },
      },
    },
  },

  computed: {
    ...mapGetters({
      getUser: "user/getUser",
      getUserInfo: "user/getInfo",
    }),

    mapLocation() {
      return {
        lat: this.formData.location.lat,
        lng: this.formData.location.lng,
      };
    },

    // detailsDescriptionErrors() {
    //   const errors = [];
    //   if (!this.$v.formData.details.description.$dirty) return errors;
    //   !this.$v.formData.details.description.required &&
    //     errors.push("Description is required.");
    //   return errors;
    // },

    locationPlaceErrors() {
      const errors = [];
      if (!this.$v.formData.location.place.$dirty) return errors;
      !this.$v.formData.location.place.required &&
        errors.push("Location is required.");
      return errors;
    },
  },

  methods: {
    ...mapActions({
      setShowLoader: "main/setShowLoader",
    }),

    getCurrentDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
    },

    informationReviewStep(stepName) {
      console.log("== informationReviewStep ==");

      // return "stepName: " + stepName;

      let output = "";

      let categoryInfo = this.categories.find(
        (element) => element.tid == this.formData.category.id
      );

      // console.log("categoryInfo")
      // console.log(categoryInfo)

      switch (stepName) {
        case "Category":
          output = categoryInfo.name;
          break;
        case "Details":
          output =
            this.formData.details.estimate +
            " - " +
            this.formData.details.description;
          break;
        case "Location":
          output = this.formData.location.place;
          break;
        case "Date":
          output =
            this.formData.date.dates[0].date +
            " " +
            this.formData.date.dates[0].time;
          break;
      }

      return output;
    },

    // Gets the updated values from the DateTimePicker child
    updatedDateValues(value) {
      this.formData.date.dates[value.index].date = value.date;
      this.formData.date.dates[value.index].time = value.time;
    },

    // Adds a new date widget to the options.
    clickAddDate() {
      if (this.formData.date.dates.length < 10) {
        this.formData.date.dates.push({ date: null, time: null });
      }
    },

    // Removes a new date widget to the options.
    clickRemoveDate() {
      if (this.formData.date.dates.length > 1) {
        this.formData.date.dates.pop();
      }
    },

    parseDate(date) {
      return dayjs(date).format("YYYY-MM-DD[T]HH:mm:ssZZ");
    },

    async uploadImages() {
      let output = [];

      if (this.formData.details.images) {
        for (const image of this.formData.details.images) {
          let binaryImage = await AuthService.loadImageFile(image);
          let fid = await AuthService.uploadImage(binaryImage);
          // console.log(fid);
          output.push({
            target_id: fid,
          });
        }
      }

      return output;
    },

    async submit() {
      this.setShowLoader(true);

      // Upload job images
      let imagesFids = await this.uploadImages();

      // ------------------
      // Dates
      // ------------------
      let dates = [];

      for (let i = 0; i < this.formData.date.dates.length; i++) {
        dates.push({
          value: this.parseDate(
            this.formData.date.dates[i].date +
              " " +
              this.formData.date.dates[i].time
          ),
        });
      }

      // ------------------
      // Payment types
      // ------------------
      let paymentTypes = [];

      for (let i = 0; i < this.formData.details.payment.length; i++) {
        console.log(this.formData.details.payment[i]);

        paymentTypes.push({
          value: this.formData.details.payment[i],
        });
      }

      // ------------------
      // Payload
      // ------------------

      const payload = {
        type: [
          {
            target_id: "job",
          },
        ],
        title: [
          {
            value: "New Job",
          },
        ],

        // Category
        field_category: [
          {
            target_id: this.formData.category.id,
          },
        ],

        // Details
        field_details: [
          {
            value: this.formData.details.description,
          },
        ],
        field_estimate_type: [
          {
            value: this.formData.details.estimate,
          },
        ],
        field_payment_type: paymentTypes,

        field_images: imagesFids,

        // Location
        field_place: [
          {
            value: this.formData.location.place,
          },
        ],
        field_location: [
          {
            lat: this.formData.location.lat,
            lng: this.formData.location.lng,
          },
        ],

        // Date
        field_date: dates,
      };

      const job = await AuthService.postJob(payload);

      console.log(job);

      this.setShowLoader(false);

      this.$router.push("/customer/pending");
    },

    // Starts when the map is fully loaded
    onMapLoaded() {
      let that = this;

      // Input
      var input = document.getElementById("place");

      // Filter options
      var options = {
        types: ["address"],
        componentRestrictions: { country: "us" },
      };

      // Gets the autocomplement object
      var autocomplete = new this.google.maps.places.Autocomplete(
        input,
        options
      );

      // The autocomplete input listen the changes when the user types
      this.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          // Gets the place
          var place = autocomplete.getPlace();

          // Updates the input field with the place name
          that.formData.location.place = place.formatted_address;

          // Updates the input field with the location lat and lng
          that.formData.location.lat = place.geometry.location.lat();
          that.formData.location.lng = place.geometry.location.lng();
        }
      );
    },

    getSubCategory(tid) {
      this.subCategories = this.categories.filter(function (category) {
        return category.parent == tid;
      });
    },

    getSubSubCategory(tid) {
      // console.log("== getSubSubCategory ==");
      // console.log(tid);

      return this.categories.filter(function (category) {
        return category.parent == tid;
      });
    },

    onSubSubCategoryClicked(tid) {
      // console.log("== onSubSubCategoryClicked ==");
      // console.log(tid);
      this.formData.category.subId = tid;
      this.formData.category.id = tid;
    },
  },

  async mounted() {
    this.setShowLoader(true);

    console.log("this.getUserInfo", this.getUserInfo);

    if(this.getUserInfo){
      if (this.getUserInfo.place) {
        this.formData.location.place = this.getUserInfo.place;
      }

      if (this.getUserInfo.location) {
        let location = JSON.parse(this.getUserInfo.location);
        this.formData.location.lat = location.lat;
        this.formData.location.lng = location.lng;
      }
    }

    // Loads the Google Map API
    await this.$gmapApiPromiseLazy();

    // Loads the Google Maps API (used to call geometry functions)
    this.google = getGoogleMapsAPI();

    this.categories = await AuthService.getCategories();
    // console.log(categories);

    this.onMapLoaded();

    this.setShowLoader(false);
  },
};
</script>

<style scoped>
.v-list-group__header {
  background-color: green;
}
.itemColor {
  color: green;
}

.row-category{
  display: flex;
  justify-content: center;
}

.column-category{
  max-width: 200px;
}

@media only screen and (max-width: 600px) {
  .row-category{
    flex-wrap: wrap;
  }

  .column-category{
    max-width: 100px;
  }
}

@media only screen and (max-width: 400px) {

  .column-category{
    max-width: 80px;
  }
}
</style>