<template>
  <div v-if="job">
    <v-card elevation="0">
      <v-card-title class="d-flex justify-space-between">
        <div>
          {{ job.category }}
        </div>
        <div>
        </div>
      </v-card-title>
      <v-card-subtitle>
        {{ job.place }}
      </v-card-subtitle>
      <v-card-text>
        <div class="row">
          <div class="col-12 col-sm-6">
            <GmapMap
              :center="JSON.parse(job.location)"
              :zoom="zoom"
              style="width: 100%; height: 250px"
              ref="gmap"
            >
            </GmapMap>
          </div>
          <div class="col-12 col-sm-6">
            <h3 class="mt-3">
              <v-icon small> mdi-calendar-clock </v-icon> Date
            </h3>
            <p>{{ helperParseDate(job.date) }}</p>

            <h3 class="mt-3">
              <v-icon small> mdi-cash-usd </v-icon> Payment Type
            </h3>
            <p>{{ job.payment_type }}</p>

            <h3 class="mt-3"><v-icon small> mdi-pen </v-icon> Estimate Type</h3>
            <p>{{ job.estimate_type }}</p>

            <h3 class="mt-3">
              <v-icon small> mdi-card-account-details-outline </v-icon> Job
              Detail
            </h3>
            <p>{{ job.details }}</p>
          </div>
        </div>

        <h3 class="mt-7 mb-1">Media</h3>
        <v-row>
          <v-col
            cols="4"
            v-for="(image, index) in helperParseImagesResponse(job.images)"
            :key="index"
          >
            <v-img
              :src="image"
              max-height="100"
              max-width="100"
              aspect-ratio="1"
            >
            </v-img>
          </v-col>
        </v-row>

        <div v-if="!this.hasEstimates && getUser.current_user.uid == job.uid"><h3 class="mt-7 mb-1">No estimates yet</h3></div>
        <div v-if="this.hasEstimates">
          <h3 class="mt-7 mb-1">Estimates for this job:</h3>
          <v-list two-line >
            <template v-for="(estimate, index2) in this.job.estimates">
              <v-list-item
                :key="index2"
                :style="[job.accepted_estimate == estimate.nid ? { 'background-color': '#A5D6A7' } : {} ]"
                :to="`/job/${job.nid}/estimate/${estimate.nid}`"
              >
                <v-list-item-avatar>
                  <v-img
                    :alt="`avatar`"
                    :src="helperGetDomain() + '/' + estimate.user_picture"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title three-line="true">
                    {{ estimate.details }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    By <strong>{{ estimate.first_name }} {{ estimate.last_name }}</strong>
                  </v-list-item-subtitle>                  
                </v-list-item-content>

                <v-list-item-action>
                  <strong>{{ estimate.amount }}</strong>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </div>


        <!-- 
        <div v-if="this.contractorEstimate.nid">
          <h3 class="mt-7 mb-1">My Estimate:</h3>
          <v-list two-line>
              <v-list-item
                @click="clickedEstimateId = this.contractorEstimate.nid; openContractorEstimateDialog()"
              >
                <v-list-item-avatar>
                  <v-img
                    :alt="`avatar`"
                    :src="helperGetDomain() + '/' + this.contractorEstimate.user_picture"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ this.contractorEstimate.details }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    By <strong>{{ this.contractorEstimate.first_name }} {{ this.contractorEstimate.last_name }}</strong>
                  </v-list-item-subtitle>                  
                </v-list-item-content>

                <v-list-item-action>
                  <strong>{{ this.contractorEstimate.amount }}</strong>
                </v-list-item-action>
              </v-list-item>
          </v-list>
        </div> -->
      </v-card-text>
      <v-card-actions class="justify-center py-5">
          <v-btn
            v-if="getUser.current_user.uid != job.uid"
            elevation="0"
            class="green lighten-3"
            @click="openEstimateDialog"
          >
            Add Estimate
          </v-btn>
        </v-card-actions>
    </v-card>

    <Dialog v-if="estimateDialog" :dialog="estimateDialog">
      <template v-slot:title>Estimate this job</template>
      <template v-slot:text>
        <v-text-field
          id="estimateAmount"
          v-model="formData.estimateAmount"
          label="Amount"
          prefix="$"
          required
          @input="$v.formData.estimateAmount.$touch()"
          @blur="$v.formData.estimateAmount.$touch()"
          :error-messages="estimateAmountErrors"
        ></v-text-field>
        <v-textarea
          name="estimateDetail"
          filled
          label="Please include details"
          auto-grow
          value=""
          required
          v-model="formData.estimateDetails"
          @input="$v.formData.estimateDetails.$touch()"
          @blur="$v.formData.estimateDetails.$touch()"
          :error-messages="estimateDetailsErrors"
        ></v-textarea>
      </template>
      <template v-slot:buttons>
        <v-btn color="primary" text @click="estimateDialog = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="addEstimate"> Submit </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";
import { getGoogleMapsAPI } from "gmap-vue";
import Helper from "@/mixins/Helper";
import Dialog from "@/components/Dialog.vue";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomerJobDetail",

  components: { Dialog },

  mixins: [validationMixin, Helper],

  data: () => ({
    job: null,
    map: null,
    marker: null,
    // Default zoom
    zoom: 14,
    estimateDialog: false,
    clickedEstimateId: null,
    hasEstimates: false,
    contractorEstimate: null,
    formData: {
      estimateAmount: null,
      estimateDetails: null,
    },
  }),

  validations: {
    formData: {
      estimateAmount: {
        required,
        numeric,
      },
      estimateDetails: {
        required
      },
    },
  },

  async created() {
    console.log("== CustomerJobDetail::created ==");
  },

  async mounted() {
    console.log("== CustomerJobDetail::mounted #1 ==");

    // Gets the job id from the parameters
    const jobId = this.$route.params.job_id;

    // Get the job details from the server
    this.job = await AuthService.getCustomerJobDetail(jobId);
    console.log("== job ==", this.job);

    // Gets the estimate of the job
    const estimates = await AuthService.getJobDetailEstimatesParent(jobId);
    console.log("== estimates ==");
    console.log(estimates);

    if (estimates.estimates) {

      this.job.estimates = JSON.parse(estimates.estimates);
      this.hasEstimates = true;

      const jobStatus = JSON.parse(this.job.estimate_status);
      this.job.accepted_estimate = jobStatus[0]['accepted_estimate'];
    }

    // Loads the Google Map API
    await this.$gmapApiPromiseLazy();

    // Loads the Google Maps API (used to call geometry functions)
    this.google = getGoogleMapsAPI();

    // await this.$nextTick();

    // Wait until the map object is fully loaded
    this.$refs.gmap.$mapPromise.then((map) => {
      // Assigns the map in order to be used in the component
      this.map = map;

      // Starts with the rest of the mapping configuration.
      this.onMapLoaded();
    });
  },

  computed: {
    ...mapGetters({
      getUser: "user/getUser",
    }),

    estimateAmountErrors() {
      const errors = [];
      if (!this.$v.formData.estimateAmount.$dirty) return errors;
      !this.$v.formData.estimateAmount.required &&
        errors.push("The amount is required.");
      !this.$v.formData.estimateAmount.numeric &&
        errors.push("Needs to be numberic");
      return errors;
    },

    estimateDetailsErrors() {
      const errors = [];
      if (!this.$v.formData.estimateDetails.$dirty) return errors;
      !this.$v.formData.estimateDetails.required &&
        errors.push("The job detail is required.");
      return errors;
    },

    estimateDetail(){
      return this.job.estimates.find(estimate => estimate.nid == this.clickedEstimateId );
    }
  },

  methods: {
    ...mapActions({
      setShowLoader: "main/setShowLoader",
    }),

    testing() {
      console.log("testing");
    },

    openEstimateDialog() {
      this.estimateDialog = true;
    },

    async addEstimate() {
      

      console.log("== addEstimate ==");

      console.log(this.formData);

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.setShowLoader(true);

      const payload = {
        jid: this.job.nid,
        amount: this.formData.estimateAmount,
        details: this.formData.estimateDetails,
      };

      const estimate = await AuthService.postEstimate(payload);

      console.log(estimate);

      this.estimateDialog = false;

      this.setShowLoader(false);
    },

    // Starts when the map is fully loaded
    onMapLoaded() {
      // Creates the marker
      this.marker = new this.google.maps.Marker({
        position: JSON.parse(this.job.location),
      });

      // Assigns the marker to the map
      this.marker.setMap(this.map);

      // Sets the marker in the new position
      this.marker.setPosition(JSON.parse(this.job.location));

      // Centers the map in the new position.
      this.map.setCenter(JSON.parse(this.job.location));
    },
  },
};
</script>

<style>
</style>