<template>
    <v-overlay 
      :value="show"
      z-index="99"
      :absolute="absolute"
      :opacity="opacity"
      color="#3786ff"
      style='z-index:20001;'
    >
      <div style="width: 200px; text-align: center;">
        <!-- <v-img
          max-width="170"
          src="@/assets/logo.png"
          class="mx-auto mt-0 mb-4"
        ></v-img>     -->
        <span class="overline font-weight-bold">Loading</span>
        <v-progress-linear
          color="white"
          indeterminate
          rounded
          height="6"
          class="mt-4"
        ></v-progress-linear>
      </div>
    </v-overlay>
</template>

<script>
export default {
  name: "Loader",
  props: ["show"],
    data: () => ({
      absolute: true,
      opacity: 1,
    }),  
};
</script>
<style scoped>
</style>