<template>
  <Page>
    <!-- <template v-slot:title> Contractor </template> -->
    <template v-slot:menu>
        <v-btn 
          plain
          color="primary"
          to="/contractor" 
          exact       
          small    
        >
          <v-icon small>mdi-magnify</v-icon> Search
        </v-btn>
        <v-btn 
          plain
          color="primary"
          to="/contractor/my-estimates" 
          exact        
          small   
        >
          <v-icon small>mdi-account-tie</v-icon> My Estimates
        </v-btn>        
    </template>    
    <template v-slot:content>
      <div 
        class="mb-10"
        style="display:flex; justify-content: center"
      >
        <div class="text-center" >
          <router-link 
            style="text-decoration: none; color: inherit;"
            to="/contractor"
          >
            <v-sheet
              class="align-center justify-center text-center d-flex flex-column px-2 mx-2 my-2"
              style="font-size: 12px; line-height: 16px;"
              color="yellow"
              outlined
              rounded
              shaped
              :width="$vuetify.breakpoint.width < 600 ? '100' : '180'"
              :height="$vuetify.breakpoint.width < 600 ? '60' : '40'"
            >Open Service Requests</v-sheet>  
          </router-link>
        </div>
        <div class="text-center">
          <router-link 
            style="text-decoration: none; color: inherit;"
            to="/contractor"
          >
            <v-sheet
              class="align-center justify-center text-center d-flex flex-column px-2 mx-2 my-2"
              style="font-size: 12px; line-height: 16px;"
              color="green"
              outlined
              rounded
              shaped
              :width="$vuetify.breakpoint.width < 600 ? '100' : '180'"

              :height="$vuetify.breakpoint.width < 600 ? '60' : '40'"

            >Pending Service Requests</v-sheet>
          </router-link>
        </div>
        <div class="text-center">
          <router-link 
            style="text-decoration: none; color: inherit;"
            to="/contractor"
          >
            <v-sheet
              class="align-center justify-center text-center d-flex flex-column px-2 mx-2 my-2"
              style="font-size: 12px; line-height: 16px;"
              color="blue"
              outlined
              rounded
              shaped
              :width="$vuetify.breakpoint.width < 600 ? '100' : '180'"
              :height="$vuetify.breakpoint.width < 600 ? '60' : '40'"
            >Approved Service Requests</v-sheet>
          </router-link>
        </div>
      </div>
      <router-view></router-view>
    </template>
  </Page>
</template>

<script>

import Page from "@/components/layout/Page.vue";

export default {
  name: "ContractorIndex",
  components: {
    Page
  },
};
</script>

<style>
</style>