<template>
  <v-card elevation="0">
    <v-card-title class="d-flex justify-space-between">
        <div>
          Search Jobs
        </div>
        <div>
          <v-btn 
            plain
            color="primary"
            @click="onClickViewMode('map')" 
            x-small               
          >
            <v-icon small>mdi-map-search</v-icon> map
          </v-btn>

          <v-btn 
            plain
            color="primary"
            @click="onClickViewMode('list')" 
            x-small
          >
            <v-icon small>mdi-format-list-bulleted</v-icon> list
          </v-btn>          
        </div>
    </v-card-title>
    <v-card-subtitle> Search available jobs in your area </v-card-subtitle>

    <v-card-text >

      <v-row>
        <!-- Location -->
        <v-col
          class="d-flex"
          cols="12"
          sm="6"
        >
          <v-text-field
            :disabled="this.isLoading"
            id="place"
            v-model="place"
            label="Location"
            required
            @input="$v.place.$touch()"
            @blur="$v.place.$touch()"                 
            :error-messages="locationPlaceErrors"
            hide-details
            append-icon="mdi-crosshairs-gps"
            @click:append="onGeolocate"
          ></v-text-field>
        </v-col>

        <!-- Category L1 -->
        <v-col
          class="d-flex"
          cols="12"
          sm="6"
          style="align-items: end"
        >
          <div>
            <v-btn
              @click="dialogCategories = true"
              depressed
            >
              Filter by Category
            </v-btn>
            <div class="d-block" v-if="selectedCategories && selectedCategoriesObject">
              <v-chip
                v-for="(selectedCategoryObject, index) in selectedCategoriesObject"
                :key="index"
                class="ma-2"
                x-small
              >
                {{ selectedCategoryObject.name }}
              </v-chip>
            </div>
          </div>

          <!-- <v-select
              :disabled="this.isLoading"
              v-model="selectedParentCategory"
              :items="categories"
              item-text="name"
              item-value="tid"
              label="Category"
              persistent-hint
              return-object
              hide-details
              clear-icon="mdi-close-circle"
              clearable
              multiple
          ></v-select> -->
        </v-col>

        <!-- Submit -->
        <v-col
          class="d-flex"
          cols="12"
        >
          <v-btn
            @click="onSearchSubmit"
            depressed
            :disabled="this.isLoading"
          >
            search
          </v-btn>
        </v-col>
      </v-row>

      <div class="my-4">

        <v-alert
          dense
          text
          color="orange"
          v-if="!jobs || !jobs.length"
        >
          No jobs found.
        </v-alert>

        <v-alert
          dense
          text
          color="green"
          v-if="jobs && jobs.length"
        >
          Found <strong>{{ jobs.length }}</strong> jobs.
        </v-alert>

      </div>   

      <!-- MAP VIEW MODE -->
      <GmapMap
        v-show="viewMode == 'map'"
        :center="center"
        :zoom="zoom"
        style="width: 100%; height: 400px"
        ref="gmap"
      >
      </GmapMap>

      <!-- LIST VIEW MODE -->
      <v-list 
        three-line 
        v-if="viewMode == 'list' && jobs && jobs.length"
      >
        <v-list-item-group>
          <template>
            <v-list-item
            v-for="(job, index) in jobs"
              :key="index"
              class="m-2"
              :to="{ name: 'CustomerJobDetail', params: { job_id: job.nid } }"
            >
              <v-list-item-content>
                <v-list-item-title class="mb-2 text-body-1">
                  <strong>{{ job.category_name }}</strong>
                </v-list-item-title>

                <v-list-item-subtitle class="mb-2 text-caption">
                  <v-icon small> mdi-calendar-clock </v-icon>
                    <template v-for="(date) in parseDatesString(job.dates)">
                    {{ date }}
                    </template>
                  <!-- {{ parseDateFormat(job.date) }} -->
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mb-2 text-caption">
                  <v-icon small> mdi-map-marker </v-icon>
                  {{ job.place.substr(0, 50) }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mb-2">
                  {{ job.details.substr(0, 100) }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text class="font-weight-bold">{{
                  job.payment_type
                }}</v-list-item-action-text>

                <v-list-item-action-text>{{
                  job.estimate_type
                }}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            
          </template>
        </v-list-item-group>
      </v-list>   

      <div class="legend-container">
        <div class="mr-4 d-flex"><span class="legend-circle legend-circle-green mr-2"></span>More than a week until service date</div>
        <div class="mr-4 d-flex"><span class="legend-circle legend-circle-yellow mr-2"></span>Less than a week until service date</div>
        <div class="mr-4 d-flex"><span class="legend-circle legend-circle-red mr-2"></span>Past due date</div>
      </div>
    
    </v-card-text>

    <Dialog :dialog="dialogCategories" width="700">
      <template v-slot:title>Types of services</template>
      <template v-slot:text>
        <v-expansion-panels flat>
          <v-expansion-panel
            v-for="(level1Category, i) in level1Categories" 
            :key="i"
          >
            <v-expansion-panel-header>
              {{level1Category.name}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>

              <v-container>
                <v-row>
                  <v-col 
                    v-for="(level2Category, ii) in getCategoriesByParent(level1Category.tid)" 
                    :key="ii"
                    col="12"
                  >
                    <h4>{{level2Category.name}}</h4>
                    <template 
                      v-for="(level3Category, iii) in getCategoriesByParent(level2Category.tid)" 
                    >
                      <v-checkbox
                        :key="iii"
                        v-model="selectedCategories"
                        :label="level3Category.name"
                        :value="level3Category.tid"
                        color="red"
                        hide-details
                      ></v-checkbox>
                    </template>
                  </v-col>
                </v-row>
              </v-container>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template v-slot:buttons>
        <v-btn color="primary" text @click="dialogCategories = false">
          OK
        </v-btn>
        <!-- <v-btn color="primary" text @click="onCategoriesDialogSubmit">
          Submit
        </v-btn> -->
      </template>
    </Dialog>
  </v-card>
</template>

<script>
import { getGoogleMapsAPI } from "gmap-vue";
import AuthService from "@/services/auth.service";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import Helper from "@/mixins/Helper";
import { required} from 'vuelidate/lib/validators'
import Dialog from "@/components/Dialog.vue";

export default {
  name: "ContractorSearchJobs",
  components: {Dialog},

  mixins: [validationMixin, Helper],

  data() {
    return {
      google: null,

      map: null,

      marker: null,

      markers: [],

      circles: [],

      viewMode: 'map',

      bounds: null,

      searchRadius: '100mi',

      categories: null,

      dialogCategories: false,

      selectedCategories: [],

      level1Categories: [],
      level2Categories: [],

      selectedParentCategory: null,

      isLoading: false,

      jobs: null,

      place: null,

      lat: null,

      lng: null,

      domain: process.env.VUE_APP_SERVER_ENDPOINT,

      // Center of the map
      center: {
        lat: 35.1175,
        lng: -89.971107,
      },

      // Default zoom
      zoom: 11,

      selectedCategory: 0,
    };
  },

  validations: {
    place: { 
      required
    },
  },    

  computed: {
    ...mapGetters({
      getUser: "user/getUser",
      getUserInfo: "user/getInfo",
    }),

    locationPlaceErrors() {
      const errors = [];
      if (!this.$v.place.$dirty) return errors;
      !this.$v.place.required && errors.push("Location is required.");
      return errors;
    },

    selectedCategoriesObject(){
      if(this.categories && this.selectedCategories.length){
        
        let that = this;

        return this.categories.filter(function (category) {
          return that.selectedCategories.find(function(cid){
            return cid == category.tid;
          });
        });

      }else{
        return null;
      }
      
    }
  },

  methods: {

    ...mapActions({
      setShowLoader: "main/setShowLoader",
    }),    
    
    // Starts when the map is fully loaded
    async onMapLoaded() {

      let that = this;

      // Input
      var input = document.getElementById("place");

      // Filter options
      var options = {
        types: ["(regions)"],
        componentRestrictions: { country: "us" },
      };
      
      // Gets the autocomplement object
      var autocomplete = new this.google.maps.places.Autocomplete(
        input,
        options
      );

      // The autocomplete input listen the changes when the user types
      this.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        async function () {
          
          // Gets the place
          var place = autocomplete.getPlace();

          // Updates the input field with the place name
          that.place = place.formatted_address;

           // Updates the input field with the location lat and lng
          that.lat = place.geometry.location.lat();
          that.lng = place.geometry.location.lng();

        }
      ); 

      const savedLocation = await AuthService.getLocationStorage();

      console.log("====> savedLocation", savedLocation);
      console.log("====> this.getUserInfo", this.getUserInfo);

      
      if(savedLocation){
        console.log("bbb2");
        this.place = savedLocation.place;

        this.lat = savedLocation.lat;
        this.lng = savedLocation.lng;

        this.jobs = await AuthService.getContractorJobs(
          savedLocation.lat + ',' + savedLocation.lng + '<=' + this.searchRadius,
          '&tid=all'
        );

        this.map.setCenter({lat: savedLocation.lat, lng: savedLocation.lng});
      }else if(this.getUserInfo){
        console.log("bbb1");
        this.place = this.getUserInfo.place;

        let location = JSON.parse(this.getUserInfo.location);

        this.lat = location.lat;
        this.lng = location.lng;

        this.jobs = await AuthService.getContractorJobs(
          location.lat + ',' + location.lng + '<=' + this.searchRadius,
          '&tid=all'
        );

        this.map.setCenter({lat: location.lat, lng: location.lng});
      }else{
        console.log("bbb3");
        // Loads everything
        // Gets the available jobs for this customer
        this.jobs = await AuthService.getContractorJobs();
      }
      

      /*
      if(this.getUserInfo){
        console.log("bbb1");
        this.place = this.getUserInfo.place;

        let location = JSON.parse(this.getUserInfo.location);

        this.lat = location.lat;
        this.lng = location.lng;

        this.jobs = await AuthService.getContractorJobs(
          location.lat + ',' + location.lng + '<=' + this.searchRadius,
          '&tid=all'
        );

        this.map.setCenter({lat: location.lat, lng: location.lng});
      }
      */
      
      if(this.jobs.length){
        this.addJobsToMap();
      }

      this.categories = await AuthService.getCategories();

      console.log("this.categories", this.categories);

      await this.initCategoriesDialog();
      
    },

    async initCategoriesDialog(){
      this.level1Categories =this.getCategoriesByParent("");
      console.log("level1", this.level1Categories);

    },


    async onCategoriesDialogSubmit(){
      console.log("== onCategoriesDialogSubmit ==");
    },

    async getCategoryByTid(tid) {
      return this.categories.find(function (category) {
        return category.tid == tid;
      });
    },

    getCategoriesByParent(parent) {
      return this.categories.filter(function (category) {
        return category.parent == parent;
      });
    },
    
    async onSearchSubmit(){

      console.log("== onSearchSubmit ==");

      this.clearMarkers();

      this.clearCircles();

      let tid = "";

      // if(this.selectedParentCategory && this.selectedParentCategory.length){

      //   for(let i = 0; i < this.selectedParentCategory.length; i++){
      //     tid += `&tid[${i}]=${this.selectedParentCategory[i].tid}`;
      //   }

      // }else{
      //   tid = '&tid=all';
      // }

      // console.log("this.selectedCategories", this.selectedCategories)

      if(this.selectedCategories && this.selectedCategories.length){

        for(let i = 0; i < this.selectedCategories.length; i++){
          tid += `&tid[${i}]=${this.selectedCategories[i]}`;
        }

      }else{
        tid = '&tid=all';
      }


      // console.log("tid", tid);
      // console.log(this.place, this.lat)

      console.log("xxx1", AuthService.getLocationStorage());

      await AuthService.setLocationStorage(
        {
          place: this.place,
          lat: this.lat,
          lng: this.lng
        }
      );

      console.log("xxx2", AuthService.getLocationStorage());

      this.jobs = await AuthService.getContractorJobs(
        this.lat + ',' + this.lng + '<=' + this.searchRadius,
        tid
      );

      // Centers the map in the new position.
      this.map.setCenter({lat: this.lat, lng: this.lng});

      if(this.jobs.length){
        this.addJobsToMap();
      }
    },

    addJobsToMap(){

      // Creates bounds to center the results
      this.bounds = new this.google.maps.LatLngBounds();
  
      // Loops thought the jobs
      this.jobs.forEach((job) => {

        // Gets and parse the location data from the server
        var location = JSON.parse(job.location);

        // Creates a point 
        var point = new this.google.maps.LatLng(location.lat, location.lng)

        /*
        => https://sites.google.com/site/gmapsdevelopment/

        http://maps.google.com/mapfiles/ms/icons/green-dot.png
        http://maps.google.com/mapfiles/ms/icons/yellow-dot.png
        https://maps.google.com/mapfiles/ms/icons/orange-dot.png
        http://maps.google.com/mapfiles/ms/icons/red-dot.png
        */

        // Creates the marker
        let marker = new this.google.maps.Marker({
          position: point,
          map: this.map,
          icon: {
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
          }
        });

        this.markers.push(marker);

        let circle = new this.google.maps.Circle({
          strokeColor: "#4CAF50",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#4CAF50",
          fillOpacity: 0.35,
          map: this.map,
          center: point,
          radius: 200,
        });

        this.circles.push(circle);

        // Adds the marker position to the bounds
        this.bounds.extend(marker.getPosition());

        let content = `
        <div class="text-left" style="min-width: 150px">
          <a href="/job/${job.nid}"><div class="font-weight-bold">${job.category_name}</div></a>
          <div>
            <img
              class="image-info"
              src="${this.domain}/${job.user_picture}"
              aspect-ratio="1"
              style="width: 40px; height: 40px; object-fit: cover; border-radius: 50%;"
            >
          </div>
          <div>${job.place}</div>
        </div>
        `

        let infowindow = new this.google.maps.InfoWindow({
          content: content,
        });        

        marker.addListener("click", () => {

          infowindow.open({
            anchor: marker,
            map: this.map,
            shouldFocus: false,
          });
        });      

      });

      // Adds the bounds to the map
      this.map.fitBounds(this.bounds);
    },

    parseDatesString(dateString){
      let dates = dateString.split(",");

      for (let i = 0; i < dates.length; i++) {
        dates[i] = this.parseDateFormat(dates[i]);
      }

      return dates;
    },

    parseDateFormat(date) {
      return dayjs(date).format("MMM DD [at] HH:MM");
    },    

    onClickViewMode(mode){
      this.viewMode = mode;
    },

    clearMarkers() {
      for (var i = 0; i < this.markers.length; i++ ) {
        this.markers[i].setMap(null);
      }
      this.markers.length = 0;
    },

    clearCircles() {
      for (var i = 0; i < this.circles.length; i++ ) {
        this.circles[i].setMap(null);
      }
      this.circles.length = 0;
    },

    async onGeolocate(){
        
        this.isLoading = true;
        let geolocationResult = await this.geolocate();
        if (geolocationResult) {
            console.log("geolocationResult", geolocationResult);

            this.lat = geolocationResult.coords.latitude;
            this.lng = geolocationResult.coords.longitude;

            const geocoder = new this.google.maps.Geocoder();

            const latlng = {
              lat: this.lat,
              lng: this.lng,
            };

            this.jobs = await AuthService.getContractorJobs(
              this.lat + ',' + this.lng + '<=' + this.searchRadius,
              '&tid=all'
            );

            this.selectedParentCategory = null;
            this.place = "";

            this.map.setCenter({lat: this.lat, lng: this.lng});

            geocoder
              .geocode({ location: latlng })
              .then((response) => {

                console.log("geocoder", response);

                if (response.results[0]) {

                  this.place = response.results[0].formatted_address;

                } else {
                  console.log("No results found");
                }
              })
              .catch((e) => console.log("Geocoder failed due to: " + e));
        }
        
        this.isLoading = false;
    },

    async geolocate() {
        return new Promise((resolve) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        console.log(position)
                        resolve(position)
                    },
                    function(error) {
                        console.log("MapService::geolocate::error")
                        console.log(error)
                        resolve(false)
                    }, {
                        enableHighAccuracy: true,
                        timeout: 5000
                    }
                );
            } else {
                console.log("MapService::geolocate::support")
                console.log("Geolocation is not supported by this browser.");
                resolve(false)
            }
        })
    },
  },
  async mounted() {

    this.setShowLoader(true);

    // Loads the Google Map API
    await this.$gmapApiPromiseLazy();

    // Loads the Google Maps API (used to call geometry functions)
    this.google = getGoogleMapsAPI();

    // Wait until the map object is fully loaded
    this.$refs.gmap.$mapPromise.then((map) => {
      // Assigns the map in order to be used in the component
      this.map = map;

      // Starts with the rest of the mapping configuration.
      this.onMapLoaded();

    });

    this.setShowLoader(false);
  },
};
</script>

<style scoped>

.legend-container{
  display: flex;
  align-items: center;
  margin: 10px 0;
  flex-direction: row;
}

@media only screen and (max-width: 400px) {
  .legend-container{
    flex-direction: column;
    align-items: flex-start;
  }
  .legend-container > div{
    margin-bottom: 5px;
  }
}


.legend-circle{
  height: 20px;
  width: 20px;
  
  border-radius: 50%;
  display: inline-block;
}
.legend-circle-green{
  background-color: green;
}
.legend-circle-yellow{
  background-color: yellow;
}
.legend-circle-red{
  background-color: red;
}
</style>