import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/webapp/Home.vue'
import About from './views/website/About.vue'
import Contact from './views/website/Contact.vue'
import Faq from './views/website/Faq.vue'
import Terms from './views/website/Terms.vue'
import Auth from './views/auth/Auth.vue'

import CustomerIndex from './views/webapp/customer/CustomerIndex.vue'
import CustomerJobList from './views/webapp/customer/CustomerJobList.vue'
import CustomerJobDetail from './views/webapp/customer/CustomerJobDetail.vue'
import CustomerNewJob from './views/webapp/customer/CustomerNewJob.vue'
import CustomerPending from './views/webapp/customer/CustomerPending.vue'
import CustomerApproved from './views/webapp/customer/CustomerApproved.vue'

import ContractorIndex from './views/webapp/contractor/ContractorIndex.vue'
import ContractorSearchJobs from './views/webapp/contractor/ContractorSearchJobs.vue'
import ContractorMyJobs from './views/webapp/contractor/ContractorMyJobs.vue'

import AccountIndex from './views/webapp/account/AccountIndex.vue'
import AccountDetail from './views/webapp/account/AccountDetail.vue'
import AccountEdit from './views/webapp/account/AccountEdit.vue'

// import EstimateIndex from './views/webapp/estimate/EstimateIndex.vue'
import EstimateDetail from './views/webapp/estimate/EstimateDetail.vue'

import store from './store/index.js'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about',
            name: 'About',
            component: About
        }, {
            path: '/contact',
            name: 'Contact',
            component: Contact
        }, {
            path: '/faq',
            name: 'Faq',
            component: Faq
        }, {
            path: '/terms',
            name: 'Terms',
            component: Terms
        }, {
            path: '/auth',
            name: 'Auth',
            component: Auth
        },
        // Account
        {
            path: '/account',
            name: 'AccountIndex',
            component: AccountIndex,
            children: [{
                    path: '',
                    component: AccountDetail,
                },
                {
                    path: 'edit',
                    component: AccountEdit,
                }
            ],
        },
        // Customers
        {
            path: '/customer',
            name: 'CustomerIndex',
            component: CustomerIndex,
            children: [{
                    path: 'list',
                    component: CustomerJobList,
                },
                {
                    path: '/customer',
                    component: CustomerNewJob,
                },
                {
                    path: '/customer/pending',
                    name: 'CustomerPending',
                    component: CustomerPending
                },
                {
                    path: '/customer/approved',
                    name: 'CustomerApproved',
                    component: CustomerApproved
                },
                {
                    path: '/job/:job_id',
                    name: 'CustomerJobDetail',
                    component: CustomerJobDetail
                },
                {
                    path: '/job/:job_id/estimate/:estimate_id',
                    name: 'EstimateDetail',
                    component: EstimateDetail
                }
            ],
        },
        // Contractor
        {
            path: '/contractor',
            name: 'ContractorIndex',
            component: ContractorIndex,
            children: [{
                    path: '',
                    component: ContractorSearchJobs,
                },
                {
                    path: 'my-estimates',
                    component: ContractorMyJobs,
                },
            ],
        },
        // Estimates
        // {
        //     path: '/estimate',
        //     name: 'EstimateIndex',
        //     component: EstimateIndex,
        //     children: [{
        //         path: 'estimate/:estimate_id',
        //         name: 'EstimateDetail',
        //         component: EstimateDetail
        //     }],
        // },
    ]
})

router.beforeEach((to, from, next) => {

    // Gets if the user is logged in
    let user = store.getters['user/getUser'];

    if (!user && (to.path == '/customer/new')) {
        next('/auth');
    }

    // if (user && to.path == '/') {
    //     next('/customer');
    // }

    next();
})

export default router;