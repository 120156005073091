<template>
  <div class="mt-4">
    <v-app-bar
      app
      color="white"
      light
      elevation="0"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <router-link 
        
        to="/"
      >
        <v-img
          v-if="$vuetify.breakpoint.width >= 600"
          class="mx-2"
          src="@/assets/logo3.png"
          max-height="100"
          max-width="200"
        ></v-img>
      </router-link>
    
      <v-spacer></v-spacer>

      <!-- CUSTOMER -->
      
      <v-btn 
        elevation="0"
        class="ml-4"
        to="/customer" 
        exact
        :small="isMobile"
        :fab="isMobile"
      >
        <span v-if="!isMobile">CUSTOMER</span> <v-icon dark :right="!isMobile">mdi-account</v-icon>
      </v-btn>

      <!-- CONTRACTOR -->
      <v-btn 
        elevation="0" 
        class="ml-4"
        to="/contractor"
        exact  
        :small="isMobile"
        :fab="isMobile"      
      >
        <span v-if="!isMobile">CONTRACTOR</span> <v-icon dark :right="!isMobile">mdi-account-hard-hat</v-icon>
      </v-btn>

      <!-- LOGIN -->
      <v-btn 
        v-if="!this.getUser"
        plain 
        class="ml-4"
        to="/auth" 
        exact
      >
        LOGIN
      </v-btn>      

      <v-menu 
        offset-y
        v-if="getUser"
      >
        <template v-slot:activator="{ on, attrs }">

          <v-avatar
            class="ml-4"
            v-bind="attrs"
            v-on="on"      
            size="42"
          >
            <img
              :src="domain + '/' + getUserInfo.user_picture"
              aspect-ratio="1"
              style="width: 100%; 100%; object-fit: cover;"
            >
          </v-avatar>
        </template>
        <v-list>
          <v-list-item-group>
            
            <!-- ACCOUNT -->
            <v-list-item
              to="/account"
            >
              <v-list-item-title>Account</v-list-item-title>
            </v-list-item>

            <!-- LOGOUT -->
            <v-list-item
              @click="logout" 
            >
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>            
          </v-list-item-group>
        </v-list>
    </v-menu>      

    </v-app-bar>

    <!-- DRAWER -->

    <v-navigation-drawer 
      v-model="drawer"
      class="drawer elevation-0"
           
      app
      hide-overlay
      fixed
    >

      <v-img
        width="200"
        aspect-ratio="4"
        src="@/assets/logo3.png"
        class="mx-auto mt-10 mb-9"
      ></v-img>

      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item to="/" exact>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/about" exact>
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>

          <v-list-item to="/faq" exact>
            <v-list-item-icon>
              <v-icon>mdi-account-question</v-icon>
            </v-list-item-icon>
            <v-list-item-title>FAQ</v-list-item-title>
          </v-list-item>               

          <v-list-item to="/terms" exact>
            <v-list-item-icon>
              <v-icon>mdi-text-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Terms</v-list-item-title>
          </v-list-item>

          <v-list-item to="/contact" exact>
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>             
   
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Header",
  // props: ["drawer"],
  data: () => ({
    drawer: false,
    group: null,
    domain: process.env.VUE_APP_SERVER_ENDPOINT,
    isMobile: false  
  }),
  computed:{
    ...mapGetters({
      getUser: "user/getUser",
      getUserInfo: "user/getInfo",
    }),

  },    

  async mounted() {
    console.log(this.getUserInfo);

    window.addEventListener('resize', this.onResize, { passive: true })

    this.onResize ();
  },

  methods: {

    ...mapActions({
      userLogout: "user/logout",
      setShowLoader: "main/setShowLoader",
      setSnackbar: "main/setSnackbar",
    }),    

      onResize () {
        console.log("resize");
        this.isMobile = window.innerWidth < 800
      },

    onIconClick(flag) {
      this.drawer = flag;
      console.log("App: " + flag);
    },        

    async logout(){

      console.log("Logout");

      this.setShowLoader(true);

      try{
        let response = await this.userLogout();
        console.log(response);
      }catch (error) {
        console.log(error);
      }

      this.setShowLoader(false);
      await this.setSnackbar({ show: true, text: 'You are logged out.' });

      if(this.$route.path != '/'){
        this.$router.push("/");
      }
      
    }
  },
};
</script>

<style scoped>
.header {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 10px;
}
.header a {
  color: #fff;
  padding-right: 5px;
  text-decoration: none;
}
</style>