<template>

  <div>
    <!-- {{ $vuetify.breakpoint.width }}{{ mobileWidth }} -->
    <v-row>
      <v-col v-if="$vuetify.breakpoint.width >= mobileWidth || this.viewModeSection == 'jobs'" sm="12" md="3" >
      <!-- <v-col  sm="12" md="3" > -->
        <v-card style="height: 100%;">
          <v-card-title>
            Pending
          </v-card-title>
          <v-card-subtitle v-if="$vuetify.breakpoint.width < mobileWidth">
            <v-btn
              x-small
              text
              disabled
            >
              Pending Service Requests
            </v-btn>
          </v-card-subtitle>
          <v-card-text >
            <p v-if="!jobs || !jobs.length">
              No Pending Service Requests yet.
            </p>

            <v-expansion-panels v-if="jobs" flat>
              <v-expansion-panel
                v-for="(job, index) in jobs"
                :key="index"
              >
                <v-expansion-panel-header>
                  <div>
                    <span style="color: green">{{ job.category }}</span>
                    <div style="font-size: 12px; margin-top: 2px">
                      Submitted: {{ job.created }}
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                      <div class="mt-2">
                        <h4>Date</h4>
                        <template v-for="(date) in parseDatesString(job.dates)">
                          {{ date }} 
                        </template>
                      </div>

                      <div class="mt-2">
                        <h4>Payment Method</h4>
                        {{ job.payment_type }}
                      </div>

                      <div class="mt-2">
                        <h4>Estimate Type</h4>
                        {{ job.estimate_type }}
                      </div>

                      <div class="mt-2">
                        <h4>Place</h4>
                        {{ job.place }}
                      </div>

                      <div class="mt-2">
                        <h4>Description</h4>
                        {{ job.details }}
                      </div>

                      <div class="mt-2 text-center">
                        <v-btn
                          text
                          color="primary"
                          @click="jobClick(job.nid)"
                        >
                        {{ job.estimates.length }} offers for this job
                        </v-btn>  
                        
                      </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.width >= mobileWidth || this.viewModeSection == 'offers'"  sm="12" md="3" >
      <!-- <v-col  sm="12" md="3" > -->
        <v-card style="height: 100%;">
          <v-card-title>
            Offers
          </v-card-title>
          <v-card-subtitle v-if="$vuetify.breakpoint.width < mobileWidth">
            <v-btn
              x-small
              text
              @click="breadcrumbClickJob"
            >
              Jobs
            </v-btn>
            >
            <v-btn
              x-small
              text
              disabled
            >
              Offers
            </v-btn>
          </v-card-subtitle>
          <v-card-text>

            <p style="font-weight: bold;" v-if="!this.selectedJob || !this.getSelectedJob.estimates.length">No offers yet</p>

            <v-list 
              v-if="this.selectedJob"
              dense 
            >
              <v-list-item-group
               
                color="primary"
              >
                <v-list-item
                  v-for="(estimate, i) in getSelectedJob.estimates"
                  :key="i"
                  @click="estimateClick(estimate.nid)"
                >
                  <v-list-item-content>
                    <v-list-item-title ><i>Re: {{ getSelectedJob.category }}</i></v-list-item-title>
                    <v-list-item-subtitle class="text--primary">{{ estimate.first_name }} {{ estimate.last_name }}</v-list-item-subtitle>
                    <v-list-item-subtitle><span style="color: white; background: green">${{ estimate.amount }}</span></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.width >= mobileWidth || this.viewModeSection == 'contact'"  sm="12" md="3" >
      <!-- <v-col  sm="12" md="3" > -->
        <v-card style="height: 100%;">
          <v-card-title>
            Contact 
          </v-card-title>
          <v-card-subtitle v-if="$vuetify.breakpoint.width < mobileWidth">
            <v-btn
              x-small
              text
              @click="breadcrumbClickJob"
            >
              Jobs
            </v-btn>
            >
            <v-btn
              x-small
              text
              @click="breadcrumbClickOffers"
            >
              Offers
            </v-btn>
            >
            <v-btn
              x-small
              text
              disabled
            >
              Contact
            </v-btn>
          </v-card-subtitle>
          <v-card-text v-if="this.getSelectedEstimate">
            <div class="mb-2" style="display: flex">
                <div>
                  <v-avatar 
                    class="mr-2"
                  >
                    <img
                    :src="domain + '/' + this.getSelectedEstimate.user_picture"
                    aspect-ratio="1"
                    style="width: 100%; 100%; object-fit: cover;"
                    >
                  </v-avatar>       
                </div>
                <div>
                  <div style="line-height: 16px; font-weight: bold;">
                    {{ this.getSelectedEstimate.first_name }} {{ this.getSelectedEstimate.last_name }}
                  </div>
                  <div style="line-height: 16px; font-weight: bold;">
                    <v-rating
                      readonly
                      class="mb-2"
                      :value="parseInt(this.getSelectedEstimate.user_rating)"
                      background-color="blue lighten-3"
                      color="blue"
                      size="18"
                      half-increments
                    ></v-rating>
                  </div>
                </div>
            </div>
            
            <h4>Accepted Payments</h4>
            <p>
              inventore veritatis et quasi architecto beatae vi
            </p>

            <h4>Experience</h4>
            <p>
              inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neq
            </p>

            <div 
              id="offer-section"
            >
              <div 
                id="offer-new" 
                style="color: green" 
                class="my-5"
                v-if="!this.getSelectedJob.accepted_estimate"
              >
                <div style="font-size: 20px" class="mb-5">
                Offer: <strong>${{ this.getSelectedEstimate.amount }}</strong>
                </div>
                <v-btn
                  depressed
                  color="blue"
                  class="mr-4"
                  @click="onClickOfferAccept"
                >
                  Accept
                </v-btn>

                <v-btn
                  depressed
                  color="blue"
                  class="mr-4"
                  @click="onClickLoadReviews"
                >
                  Check Reviews
                </v-btn>
              </div>

            </div>

           <!-- {{this.getSelectedEstimate}} -->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.width >= mobileWidth || this.viewModeSection == 'reviews'"  sm="12" md="3" >
      <!-- <v-col  sm="12" md="3" > -->
        <v-card style="height: 100%;">
          <v-card-title>
            Reviews & Ratings
          </v-card-title>
          <v-card-subtitle v-if="$vuetify.breakpoint.width < mobileWidth">
            <v-btn
              x-small
              text
              @click="breadcrumbClickJob"
            >
              Jobs
            </v-btn>
            >
            <v-btn
              x-small
              text
              @click="breadcrumbClickOffers"
            >
              Offers
            </v-btn>
            >
            <v-btn
              x-small
              text
              @click="breadcrumbClickContact"
            >
              Contact
            </v-btn>
            >
            <v-btn
              x-small
              text
              disabled
            >
              Reviews
            </v-btn>
          </v-card-subtitle>
          <v-card-text >
            <v-list 
              v-if="this.reviews"
              dense 
            >
              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  v-for="(review, i) in this.reviews"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-rating
                        readonly
                        class="mb-2"
                        :value="parseInt(review.rating_value)"
                        background-color="blue lighten-3"
                        color="blue"
                        size="18"
                        half-increments
                      ></v-rating>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ review.rating_description }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthService from '@/services/auth.service';
import Helper from "@/mixins/Helper";
import dayjs from "dayjs";

export default {
name: "CustomerPending",

components: {},

mixins: [Helper],

data: () => ({
  domain: process.env.VUE_APP_SERVER_ENDPOINT,

  customerJobs: null,
  jobs: null,

  breadcrumb: null,

  step: 1,

  selectedJob: null,

  selectedEstimate: null,

  reviews: [],

  // new, accepted, complete
  viewMode: 'jobs',

  mobileWidth: 600
}),

computed:{
  ...mapGetters({
    getUser: "user/getUser",
  }),

  getSelectedJob(){
    if(this.selectedJob){
      let that = this;
      return this.jobs.find(function(job){
        return job.nid == that.selectedJob;
      });
    }else{
      return null;
    }
  },

  getSelectedEstimate(){
    console.log("getSelectedEstimate", this.selectedEstimate, this.getSelectedJob)
    if(this.getSelectedJob && this.selectedEstimate && this.getSelectedJob.estimates){
      
      let that = this;
      return this.getSelectedJob.estimates.find(function(estimate){
        return estimate.nid == that.selectedEstimate;
      });
    }else{
      return null;
    }
    
  },

  viewModeSection(){
    return this.viewMode;
  }

},   

async created() {
  console.log("CustomerJobList::created()");
},

async mounted() {
  console.log("CustomerJobList::mounted()");

  this.setShowLoader(true);

  console.log(this.getUser);

  if(this.getUser){
   
    await this.getJobs('new');
  }

  this.setShowLoader(false);
},

// watch: { 
//   viewMode: function(newView) { 
    
//     this.getJobs(newView);
//   }
// },

methods: {

  ...mapActions({
    setShowLoader: "main/setShowLoader",
  }),

  async onClickOfferAccept(){
    console.log("== onClickOfferAccept ==")

    const payload = {
      jid: this.getSelectedJob.nid,
      eid: this.getSelectedEstimate.nid,
    };

    console.log("payload", payload)

    const acceptedEstimate = await AuthService.postAcceptEstimate(payload);
    console.log("acceptedEstimate", acceptedEstimate);

    this.$router.push("/customer/approved");
  },

  async jobClick(jid){
    this.selectedJob = jid;
    this.viewMode = 'offers';
    this.reviews=[];
    console.log("jobClick", jid);
  },

  async getJobs(status){
  
    // Loads the customer jobs
    this.jobs = await AuthService.apiCustomerGetJobs(status);

    // Loops over all the jobs and parses the estimates
    this.jobs = this.jobs.map(function(job){

      if(job.estimates){
        job.estimates = JSON.parse(job.estimates);
      }
    
      return job;
    });


    console.log("this.jobs", this.jobs);
  },

  async estimateClick(eid){
    this.selectedEstimate = eid;
    this.reviews=[];
    this.viewMode = 'contact';
    console.log("estimateClick", eid);

  },


  async breadcrumbClickJob(){

    console.log("breadcrumbClickJob")
    this.selectedJob = null;
    this.selectedEstimate = null;

    this.reviews=[];
    this.viewMode = 'jobs';
  },

  async breadcrumbClickOffers(){

    console.log("breadcrumbClickOffers")

    this.reviews=[];
    this.viewMode = 'offers';
  },

  async breadcrumbClickContact(){

    console.log("breadcrumbClickContact")

    this.reviews=[];
    this.viewMode = 'contact';
  },

  async onClickLoadReviews(){
    console.log("onClickLoadReviews")

    this.reviews = await AuthService.apiCustomerGetReviews(this.getSelectedEstimate.uid);
    console.log("reviews", this.reviews);

    this.viewMode = 'reviews';
  },

  parseDatesString(dateString){
    let dates = dateString.split(",");

    for (let i = 0; i < dates.length; i++) {
      dates[i] = this.parseDateFormat(dates[i]);
    }

    return dates;
  },

  parseDateFormat(date) {
    return dayjs(date).format("MMM DD [at] HH:MM");
  },

  // onClickJob(nid) {
  //   console.log("== onClickJob ==");
  //   console.log(nid);
  // },
},
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>