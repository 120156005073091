<template>
  <Page>
    <template v-slot:content>
      <div 
        class="mb-10"
        style="display:flex; justify-content: center"
      >
        <div class="text-center" >
          <router-link 
            style="text-decoration: none; color: inherit;"
            to="/customer"
            exact
          >
            <v-sheet
              class="button-left align-center justify-center text-center d-flex flex-column px-2 mx-2 my-2"
              style="font-size: 12px; line-height: 16px;"
              :color="!routing.name ? 'yellow' : 'yellow lighten-4'"
              outlined
              rounded
              shaped
              :width="$vuetify.breakpoint.width < 600 ? '100' : '180'"
              :height="$vuetify.breakpoint.width < 600 ? '60' : '40'"
            >New Service Request</v-sheet>  
          </router-link>
        </div>
        <div class="text-center">
          <router-link 
            style="text-decoration: none; color: inherit;"
            to="/customer/pending"
            exact
          >
            <v-sheet
              class="button-center align-center justify-center text-center d-flex flex-column px-2 mx-2 my-2"
              style="font-size: 12px; line-height: 16px;"
              :color="routing.name == 'CustomerPending' ? 'green' : 'green lighten-4'"
              outlined
              rounded
              shaped
              :width="$vuetify.breakpoint.width < 600 ? '100' : '180'"

              :height="$vuetify.breakpoint.width < 600 ? '60' : '40'"

            >Pending Service Requests</v-sheet>
          </router-link>
        </div>
        <div class="text-center">
          <router-link 
            style="text-decoration: none; color: inherit;"
            to="/customer/approved"
          >
            <v-sheet
              class="button-right align-center justify-center text-center d-flex flex-column px-2 mx-2 my-2"
              style="font-size: 12px; line-height: 16px;"
              :color="routing.name == 'CustomerApproved' ? 'blue' : 'blue lighten-4'"
              outlined
              rounded
              shaped
              :width="$vuetify.breakpoint.width < 600 ? '100' : '180'"
              :height="$vuetify.breakpoint.width < 600 ? '60' : '40'"
            >Approved Service Requests</v-sheet>
          </router-link>
        </div>
      </div>
      <router-view></router-view>
    </template>
  </Page>
</template>

<script>

import Page from "@/components/layout/Page.vue";

export default {
  name: "CustomerIndex",
  components: {
    Page
  },

  computed: {
    routing(){
      return this.$route;
    }
  },

  async mounted() {
  },
};
</script>

<style >

.router-link-active .v-sheet {
  border-color: grey  !important;
}


.desktop-sheet{

}
.mobile-sheet{
  
}
</style>