// https://morioh.com/p/8e0b9d2ba0f9
export default function authFileHeader(filename) {

    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
        return {
            'Content-Type': 'application/octet-stream',
            'Accept': 'application/json',
            "Content-Disposition": `file; filename="${filename}"`,
            'Authorization': 'Bearer ' + user.access_token
        };
    } else {
        return {};
    }
}