import {
    SET_USER,
    DELETE_USER,
    SET_USER_INFO
} from './../mutation-types'

// import Vue from 'vue';

import AuthService from '@/services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

const info = JSON.parse(localStorage.getItem('info'));

export default {
    namespaced: true,

    state: {
        user: user,
        info: info,
        // jobs: []
    },

    getters: {
        getUser(state) {
            return state.user;
        },
        getInfo(state) {
            return state.info;
        },
        // getUserJobs(state) {
        //     return state.jobs;
        // }
    },

    actions: {

        async loadInfo({ commit }, uid) {

            try {

                let info = await AuthService.getUserInfo(uid);
                commit('SET_USER_INFO', info);

                return {
                    status: true
                }
            } catch (error) {

                return {
                    status: false,
                    message: error
                }
            }
        },

        async setUserInfo({ commit }, info) {

            commit('SET_USER_INFO', info);

            return {
                status: true
            }
        },

        async setUser({ commit }, user) {

            commit('SET_USER', user);

            return {
                status: true
            }
        },

        // async login({ commit }, payload) {

        //     try {

        //         let response = await AuthService.login(payload);
        //         commit('SET_USER', response);

        //         return {
        //             status: true
        //         }
        //     } catch (error) {

        //         return {
        //             status: false,
        //             message: error
        //         }
        //     }
        // },

        // https://stackoverflow.com/a/65394676/447752
        async register(context, payload) {

            try {

                await AuthService.register(payload);

                return {
                    status: true
                }
            } catch (error) {

                return {
                    status: false,
                    message: error
                }
            }
        },

        async logout({ commit }) {

            // Removes the local storage item
            await AuthService.logout();

            // Removes the logged status
            await commit('DELETE_USER');

        },
    },

    mutations: {
        [SET_USER](state, user) {
            state.user = user;
        },
        [SET_USER_INFO](state, info) {
            state.info = info;
        },
        [DELETE_USER](state) {
            state.user = null;
        },
        // [LOAD_JOBS](state, jobs) {
        //     Vue.set(state, 'jobs', [...jobs]);
        // },
    }
}