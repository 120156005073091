<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-main>
      <transition 
        name="fade"
        mode="out-in"
      >
        <router-view/>
      </transition>
    </v-main>
    <Loader
      :show="showLoader"
    />
    <Snackbar ref="snackbar" />
  </v-app>
</template>

<script>

import Loader from "@/components/Loader.vue"
import { /*mapActions, */mapGetters } from "vuex";
import Snackbar from "@/components/Snackbar.vue";

export default {
  name: 'App',

  components: {
    Loader,
    Snackbar,    
  },

  data: () => ({
    //
  }),

  computed:{
    ...mapGetters({
      getShowLoader: "main/getShowLoader",
    }),
    
    showLoader(){
      return this.getShowLoader;
    }
  },     
};
</script>
<style >
.theme--light.v-application {
  background-color: "#FAFBFD";
}
.custom-input-error{
    padding: 12px 12px 0 12px;
    color: #ff5252 !important;
    caret-color: #ff5252 !important;  
    font-size: 12px;  
}
/* .v-main__wrap{
  display:flex; 
  flex-flow:column;
} */
</style>