<template>

  <div>
    <!-- {{ $vuetify.breakpoint.width }}{{ mobileWidth }} -->
    <v-row>
      <v-col v-if="$vuetify.breakpoint.width >= mobileWidth || this.viewModeSection == 'jobs'" sm="12" md="3" >
      <!-- <v-col  sm="12" md="3" > -->
        <v-card style="height: 100%;">
          <v-card-title>
            Approved
          </v-card-title>
          <v-card-subtitle v-if="$vuetify.breakpoint.width < mobileWidth">
            <v-btn
              x-small
              text
              disabled
            >
              Approved Service Requests
            </v-btn>
          </v-card-subtitle>
          <v-card-text >
            <p v-if="!jobs || !jobs.length">
              No Approved Service Requests yet.
            </p>

            <v-expansion-panels v-if="jobs" flat>
              <v-expansion-panel
                v-for="(job, index) in jobs"
                :key="index"
              >
                <v-expansion-panel-header>
                  <div>
                    <span style="color: green">{{ job.category }}</span>
                    <div style="font-size: 12px; margin-top: 2px">
                      Submitted: {{ job.created }}
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                      <div class="mt-2">
                        <h4>Date</h4>
                        <template v-for="(date) in parseDatesString(job.dates)">
                          {{ date }} 
                        </template>
                      </div>

                      <div class="mt-2">
                        <h4>Payment Method</h4>
                        {{ job.payment_type }}
                      </div>

                      <div class="mt-2">
                        <h4>Estimate Type</h4>
                        {{ job.estimate_type }}
                      </div>

                      <div class="mt-2">
                        <h4>Place</h4>
                        {{ job.place }}
                      </div>

                      <div class="mt-2">
                        <h4>Description</h4>
                        {{ job.details }}
                      </div>

                      <div class="mt-2 text-center">
                        <v-btn
                          text
                          color="primary"
                          @click="jobClick(job.nid)"
                        >
                        accepted offer
                        </v-btn>  
                        
                      </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.width >= mobileWidth || this.viewModeSection == 'contact'"  sm="12" md="3" >
      <!-- <v-col  sm="12" md="3" > -->
        <v-card style="height: 100%;">
          <v-card-title>
            Contact 
          </v-card-title>
          <v-card-subtitle v-if="$vuetify.breakpoint.width < mobileWidth">
            <v-btn
              x-small
              text
              @click="breadcrumbClickJob"
            >
              Jobs
            </v-btn>
            >
            <v-btn
              x-small
              text
              disabled
            >
              Contact
            </v-btn>
          </v-card-subtitle>
          <v-card-text v-if="this.getSelectedEstimate">
            <div class="mb-2" style="display: flex">
                <div>
                  <v-avatar 
                    class="mr-2"
                  >
                    <img
                    :src="domain + '/' + this.getSelectedEstimate.user_picture"
                    aspect-ratio="1"
                    style="width: 100%; 100%; object-fit: cover;"
                    >
                  </v-avatar>  
                </div>
                <div>

                  <span style="line-height: 16px; font-weight: bold;">
                    {{ this.getSelectedEstimate.first_name }} {{ this.getSelectedEstimate.last_name }}
                  </span>
                  <div style="line-height: 16px; font-weight: bold;">
                    <v-rating
                      readonly
                      class="mb-2"
                      :value="parseInt(this.getSelectedEstimate.user_rating)"
                      background-color="blue lighten-3"
                      color="blue"
                      size="18"
                      half-increments
                    ></v-rating>
                  </div>
                </div>

            </div>
            
            <h4>Accepted Payments</h4>
            <p>
              inventore veritatis et quasi architecto beatae vi
            </p>

            <h4>Experience</h4>
            <p>
              inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neq
            </p>

            <div 
              id="offer-section"
            >
         
              <div 
                id="offer-accepted" 
                class="my-5"
                v-if="this.getSelectedJob.accepted_estimate && this.getSelectedJob.accepted_estimate == this.getSelectedEstimate.nid"
              >

               <div style="color: green; font-size: 20px" class="mb-5">
                Accepted offer: <strong>${{ this.getSelectedEstimate.amount }}</strong>
              </div>

                <h4>Business Address</h4>
                <p>
                  inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam volup
                </p>

                <h4>Phone Number</h4>
                <p>
                  1234567899
                </p>

                <h4>Email</h4>
                <p>
                  test@gmail.com
                </p>

                <div style="text-align: center;">
                  <v-btn
                    text
                    class="primary mx-2"
                    @click="onClickRelist"
                  >
                    Relist
                  </v-btn>              
                  <v-btn
                  text
                    class="primary mx-2"
                    @click="onClickReview"
                  >
                    Review
                  </v-btn>
                </div>

              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.width >= mobileWidth || this.viewModeSection == 'review'"  sm="12" md="3" >
      <!-- <v-col  sm="12" md="3" > -->
        <v-card style="height: 100%;">
          <v-card-title>
            Review
          </v-card-title>
          <v-card-subtitle v-if="$vuetify.breakpoint.width < mobileWidth">
            <v-btn
              x-small
              text
              @click="breadcrumbClickJob"
            >
              Jobs
            </v-btn>
            >
            <v-btn
              x-small
              text
              @click="breadcrumbClickOffer"
            >
              Contact
            </v-btn>
            >
            <v-btn
              x-small
              text
              disabled
            >
              Review
            </v-btn>
          </v-card-subtitle>
          <v-card-text v-if="this.getSelectedEstimate">
            <div
              class="text-center"
              style="max-width: 480px; margin: auto;"
            >
              <div class="text-left my-2">Rate your experience with the contractor</div>
              <v-textarea
                name="description"
                filled
                label="Please include details"
                auto-grow
                value=""
                required
                v-model="formRatingData.description"
                @input="$v.formRatingData.description.$touch()"
                @blur="$v.formRatingData.description.$touch()"
                :error-messages="descriptionErrors"
              ></v-textarea>
              <v-rating
                class="mb-2"
                :value="parseInt(this.getSelectedEstimate.user_rating)"
                v-model="formRatingData.rating"
                background-color="blue lighten-3"
                color="blue"
                
              ></v-rating>
              <v-btn 
                color="primary" 
                text 
                @click="ratingDialog = true"
                >
                Rate
              </v-btn>
              <!-- {{ this.getSelectedEstimate }} -->
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- RATING DIALOG -->
    <Dialog v-if="ratingDialog" :dialog="ratingDialog">
      <template v-slot:title>Rate this estimate</template>
      <template v-slot:text>
        Do you want to rate this estimate?
      </template>
      <template v-slot:buttons>
  
        <v-btn color="primary" text @click="ratingDialog = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="rateEstimate"> 
          Rate estimate 
        </v-btn>

      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthService from '@/services/auth.service';
import Helper from "@/mixins/Helper";
import dayjs from "dayjs";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Dialog from "@/components/Dialog.vue";


export default {
name: "CustomerApproved",

components: {Dialog},

mixins: [Helper, validationMixin],

data: () => ({
  domain: process.env.VUE_APP_SERVER_ENDPOINT,

  customerJobs: null,
  jobs: null,

  ratingDialog: false,

  breadcrumb: null,

  step: 1,

  selectedJob: null,

  selectedEstimate: null,

  // new, accepted, complete
  viewMode: 'jobs',

  mobileWidth: 600,

  formRatingData: {
    description: "",
    rating: 1,
  },
}),

validations: {
  formRatingData: {
    description: {
      required,
    },
  },
},

computed:{
  ...mapGetters({
    getUser: "user/getUser",
  }),

  descriptionErrors() {
      const errors = [];
      
      if (!this.$v.formRatingData.description.$dirty) {
        return errors;
      }

      if(!this.$v.formRatingData.description.required){
        errors.push("The description is required.");
      }
        
      return errors;
    },

  getSelectedJob(){
    if(this.selectedJob){
      let that = this;
      return this.jobs.find(function(job){
        return job.nid == that.selectedJob;
      });
    }else{
      return null;
    }
  },

  getSelectedEstimate(){
    console.log("getSelectedEstimate", this.selectedEstimate, this.getSelectedJob)
    if(this.getSelectedJob && this.selectedEstimate && this.getSelectedJob.estimates){
      
      let that = this;
      return this.getSelectedJob.estimates.find(function(estimate){
        return estimate.nid == that.selectedEstimate;
      });
    }else{
      return null;
    }
    
  },

  viewModeSection(){
    return this.viewMode;
  }

},   

async created() {
  console.log("CustomerJobList::created()");
},

async mounted() {
  console.log("CustomerJobList::mounted()");

  this.setShowLoader(true);

  console.log(this.getUser);

  if(this.getUser){
   
    await this.getJobs('accepted');
  }

  this.setShowLoader(false);
},

// watch: { 
//   viewMode: function(newView) { 
    
//     this.getJobs(newView);
//   }
// },

methods: {

  ...mapActions({
    setShowLoader: "main/setShowLoader",
  }),

  // Sends request to rate the estimate
  async rateEstimate(){

    this.setShowLoader(true);

    console.log("== rateEstimate ==");
    console.log(this.formRatingData);

    const payload = {
      eid: this.getSelectedEstimate.nid,
      description: this.formRatingData.description,
      rating: this.formRatingData.rating,
    };

    const ratedEstimate = await AuthService.postRateEstimate(payload);
    console.log("ratedEstimate", ratedEstimate);

    await this.getJobs('accepted');

    this.ratingDialog = false

    this.setShowLoader(false);
  },
  
  async onClickOfferAccept(){
    console.log("== onClickOfferAccept ==")

    const payload = {
      jid: this.getSelectedJob.nid,
      eid: this.getSelectedEstimate.nid,
    };

    console.log("payload", payload)

    const acceptedEstimate = await AuthService.postAcceptEstimate(payload);
    console.log("acceptedEstimate", acceptedEstimate);
  },

  async onClickOfferDecline(){
    console.log("== onClickOfferDecline ==")
  },

  async jobClick(jid){
    this.selectedJob = jid;
    this.selectedEstimate = this.getSelectedJob.accepted_estimate;

    this.viewMode = 'contact';
    // console.log("jobClick", jid);
    // console.log("getSelectedJob", this.getSelectedJob)

    this.formRatingData.description = this.getSelectedEstimate.rating_description;
    this.formRatingData.rating = parseInt(this.getSelectedEstimate.rating_value);

  },

  async onClickReview(){

    // console.log("this.formRatingData", this.formRatingData);

    this.viewMode = 'review';

  },

  async onClickRelist(){
    console.log("== onClickRelist ==");

    console.log("== onClickOfferAccept ==")

    const payload = {
      jid: this.getSelectedJob.nid,
      eid: this.getSelectedEstimate.nid,
    };

    console.log("payload", payload)

    const relistEstimate = await AuthService.postRelistEstimate(payload);
    console.log("relistEstimate", relistEstimate);

    this.$router.push("/customer/pending");
  },

  async getJobs(status){
  
    // Loads the customer jobs
    this.jobs = await AuthService.apiCustomerGetJobs(status);

    // Loops over all the jobs and parses the estimates
    this.jobs = this.jobs.map(function(job){

      if(job.estimates){
        job.estimates = JSON.parse(job.estimates);
      }
    
      return job;
    });


    console.log("this.jobs", this.jobs);
  },


  async breadcrumbClickJob(){

    console.log("breadcrumbClickJob")
    this.selectedJob = null;
    this.selectedEstimate = null;

    this.viewMode = 'jobs';
  },

  async breadcrumbClickOffer(){

    console.log("breadcrumbClickOffers")
    // this.selectedJob = null;
    // this.selectedEstimate = null;

    this.viewMode = 'contact';
  },



  // onClickViewMode(mode){
  //   this.viewMode = mode;
  // },

  parseDatesString(dateString){
    let dates = dateString.split(",");

    for (let i = 0; i < dates.length; i++) {
      dates[i] = this.parseDateFormat(dates[i]);
    }

    return dates;
  },

  parseDateFormat(date) {
    return dayjs(date).format("MMM DD [at] HH:MM");
  },

  // onClickJob(nid) {
  //   console.log("== onClickJob ==");
  //   console.log(nid);
  // },
},
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>