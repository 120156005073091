<template>
  <v-row class="my-2 px-2 light-blue lighten-5">
    <v-col 
        cols="12"
        sm="6"
    >
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Select date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[v => !!v || 'Required']"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>   
    </v-col>
    <v-col 
        cols="12"
        sm="6"
    >
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            label="Select time"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[v => !!v || 'Required']"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="time"
          full-width
          @click:minute="$refs.menu2.save(time)"
        ></v-time-picker>
      </v-menu>
    </v-col>    
  </v-row>


</template>

<script>
export default {
  name: "DateTimePicker",
  props: ["index"],
  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOS,
    time: null,
    menu: false,    
    menu2: false,
  }),

  watch: { 
    date: function(newDate) { 
      console.log("newDate")
      console.log(newDate)
      this.updatedDateValues();
    },

    time: function(newTime) { 
      console.log("newTime")
      console.log(newTime)
      this.updatedDateValues();
    }    
  },  

  methods:{
    updatedDateValues(){
      this.$emit('updatedDateValues', {'date': this.date, 'time': this.time, 'index': this.index})
    }
  }


};
</script>